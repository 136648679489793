/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** RFC3339 encoded UTC date string i.e 2019-04-28T15:00:00Z */
  DateTime: string;
  /** A ISO 8601 duration string */
  Duration: string;
  HTML: string;
  /** A signed JSON Web Token */
  JWT: string;
  MoneyInput: string;
  /** A URL */
  URL: string;
  /** A multipart file upload */
  Upload: any;
};

export type AudVerifiableBankAccount = VerifiableBankAccount & {
  __typename: 'AUDVerifiableBankAccount';
  accountNumber: Scalars['String'];
  bsbCode: Scalars['String'];
  created: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  investingEntity: InvestingEntity;
  isActiveAccount: Scalars['Boolean'];
  isDefaultAccount: Scalars['Boolean'];
  name: Scalars['String'];
  nickname: Scalars['String'];
  status: BankAccountStatus;
};

/** Represents a user account object, with profile information. */
export type Account = {
  __typename: 'Account';
  accountLockedReason?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated remove once no longer in use */
  accountManager?: Maybe<AccountManager>;
  /** Returns the active investing entity for the account */
  activeInvestingEntity?: Maybe<InvestingEntity>;
  address?: Maybe<Address>;
  /** @deprecated Deprecated remove once no longer in use */
  availableFunds?: Maybe<AccountAvailableFunds>;
  country?: Maybe<Country>;
  created: Scalars['DateTime'];
  dayOfBirth?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  emailPreferences?: Maybe<EmailPreferences>;
  firstName?: Maybe<Scalars['String']>;
  /** HMAC SHA-256 generated hash to identify the user in Front */
  frontChatHash?: Maybe<Scalars['String']>;
  /** A user is required to provide a minimal set of account details before they can view the bulk of the app */
  hasProvidedAccountDetails: Scalars['Boolean'];
  id: Scalars['ID'];
  intercomHash?: Maybe<Scalars['String']>;
  /** Returns all investing entities for the account */
  investingEntities?: Maybe<Array<InvestingEntity>>;
  isAccountLocked?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  monthOfBirth?: Maybe<Scalars['Int']>;
  /** Returns a notification for the given type belonging to this account, null if there is no such notification */
  notification?: Maybe<AddInvestingEntityNotification | ConfirmEmailAddressNotification | ConfirmIdentityDocumentDetailsNotification | ProvideCityOfBirthNotification | ReattemptVerifyAddressNotification | ReattemptVerifyBiometricIdentityNotification | VerifyAddressNotification | VerifyBiometricIdentityNotification | VerifyIdentityNotification>;
  /** All notifications for this account, optionaly filtered by status */
  notifications: Array<AddInvestingEntityNotification | ConfirmEmailAddressNotification | ConfirmIdentityDocumentDetailsNotification | ProvideCityOfBirthNotification | ReattemptVerifyAddressNotification | ReattemptVerifyBiometricIdentityNotification | VerifyAddressNotification | VerifyBiometricIdentityNotification | VerifyIdentityNotification>;
  phoneNumber?: Maybe<PhoneNumber>;
  /** @deprecated Deprecated remove once no longer in use */
  placeOfBirth?: Maybe<PlaceOfBirth>;
  preferredName?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated remove once no longer in use */
  primaryCitizenship?: Maybe<Country>;
  termsAgreed?: Maybe<Scalars['Boolean']>;
  timeZone?: Maybe<Scalars['String']>;
  yearOfBirth?: Maybe<Scalars['Int']>;
};


/** Represents a user account object, with profile information. */
export type AccountNotificationArgs = {
  type: NotificationType;
};


/** Represents a user account object, with profile information. */
export type AccountNotificationsArgs = {
  status?: InputMaybe<NotificationStatus>;
};

export enum AccountAvailableFunds {
  Between_50KAnd_250K = 'BETWEEN_50K_AND_250K',
  Between_250KAnd_1M = 'BETWEEN_250K_AND_1M',
  Over_1M = 'OVER_1M',
  Under_50K = 'UNDER_50K'
}

export type AccountDetailsInput = {
  addressCity: Scalars['String'];
  /** ISO 3166-1 numeric-3 account's address country */
  addressCountry: Scalars['String'];
  addressLine1: Scalars['String'];
  addressPlaceId?: InputMaybe<Scalars['String']>;
  addressPostCode: Scalars['String'];
  /** To capture the raw input from the user, in case the search API results don't match */
  addressRawSearch?: InputMaybe<Scalars['String']>;
  addressSuburb: Scalars['String'];
  /** @deprecated Deprecated remove once no longer in use */
  availableFunds?: InputMaybe<AccountAvailableFunds>;
  callingCode: Scalars['String'];
  consent: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  /**
   * ISO 3166-1 numeric-3 account's primary citizenship country
   * @deprecated Deprecated remove once no longer in use
   */
  primaryCitizenshipCountry?: InputMaybe<Scalars['String']>;
};

export type AccountFromToken = {
  __typename: 'AccountFromToken';
  accountDetailsVerificationStatus: AccountVerificationStatus;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identityVerificationStatus: AccountVerificationStatus;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
};

export type AccountManager = {
  __typename: 'AccountManager';
  calendlyUrl?: Maybe<Scalars['URL']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  profileImageUrl?: Maybe<Scalars['String']>;
};

/** A notification related to an account */
export type AccountNotification = {
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export enum AccountVerificationStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

/** Represents an accreditation for the Investing Entity. */
export type Accreditation = {
  country: Country;
  id: Scalars['ID'];
  updated: Scalars['DateTime'];
};

export enum AccreditationStatus {
  AwaitingStep_2 = 'AWAITING_STEP_2',
  Complete = 'COMPLETE',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type ActivateAccountInput = {
  password: Scalars['String'];
  termsAgreed: Scalars['Boolean'];
  token: Scalars['String'];
};

export type AddBankAccountNotification = InvestingEntityNotification & Notification & {
  __typename: 'AddBankAccountNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type AddInvestingEntityNotification = AccountNotification & Notification & {
  __typename: 'AddInvestingEntityNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

/** Represents a location. */
export type Address = {
  __typename: 'Address';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  /** Administrative area of the address (e.g. state, province, region) */
  administrativeArea?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country?: Maybe<Country>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  placeId?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  status: AddressStatus;
  suburb?: Maybe<Scalars['String']>;
};

export type AddressSearchResult = {
  __typename: 'AddressSearchResult';
  description: Scalars['String'];
  placeId: Scalars['ID'];
};

export type AddressSearchResults = {
  __typename: 'AddressSearchResults';
  results: Array<AddressSearchResult>;
  sessionToken: Scalars['String'];
};

export enum AddressSearchType {
  Address = 'ADDRESS',
  Cities = 'CITIES'
}

export enum AddressStatus {
  Pending = 'PENDING',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

export type Admin = {
  __typename: 'Admin';
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type Adviser = {
  email: Scalars['String'];
  fullName: Scalars['String'];
};

/** An offer allocation request that has been placed. */
export type AllocationRequest = {
  __typename: 'AllocationRequest';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  investingEntity: InvestingEntity;
  offer: Offer;
  status: AllocationRequestStatus;
  unitCount: Scalars['Int'];
  unitPrice: Money;
};

export enum AllocationRequestStatus {
  /** @deprecated replaced by APPROVED */
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  /** @deprecated replaced by APPROVED */
  Completed = 'COMPLETED',
  /** @deprecated replaced by APPROVED */
  Funded = 'FUNDED',
  Requested = 'REQUESTED'
}

/** Details of an asset that is owned by a Fund */
export type Asset = {
  __typename: 'Asset';
  /** @deprecated Use optionalCardImage instead */
  cardImageUrl: Scalars['URL'];
  country: Country;
  descriptionBlock: AssetDescriptionBlock;
  galleryImages: Array<Image>;
  id: Scalars['ID'];
  /** @deprecated Use latestKeyMetrics */
  landArea?: Maybe<Scalars['Int']>;
  latestKeyMetrics?: Maybe<AssetKeyMetrics>;
  /** @deprecated Use latestKeyMetrics */
  latestValuation?: Maybe<Money>;
  location: Scalars['String'];
  metricsBlock: AssetMetricsBlock;
  name: Scalars['String'];
  /** @deprecated Use latestKeyMetrics */
  netLettableArea?: Maybe<Scalars['Int']>;
  optionalCardImageUrl?: Maybe<Scalars['URL']>;
  shortDescription: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  thumbnailImageUrl: Scalars['URL'];
  vimeoVideoId?: Maybe<Scalars['String']>;
};

export type AssetDescriptionBlock = {
  __typename: 'AssetDescriptionBlock';
  content: Scalars['HTML'];
  title: Scalars['String'];
};

export type AssetKeyMetrics = {
  __typename: 'AssetKeyMetrics';
  effectiveDate: Scalars['DateTime'];
  id: Scalars['ID'];
  landArea: Scalars['Int'];
  latestValuation: Money;
  netLettableArea: Scalars['Int'];
  occupancy: Fraction;
  purchasePrice: Money;
  weightedAverageLeaseTerm: Scalars['Float'];
};

export type AssetMetric = {
  __typename: 'AssetMetric';
  id: Scalars['ID'];
  index: Scalars['Int'];
  name: Scalars['String'];
  tooltip: Scalars['String'];
  value: Scalars['String'];
};

export type AssetMetricsBlock = {
  __typename: 'AssetMetricsBlock';
  /** Metrics may be forecasted as at a specific date. `null` if metrics are 'as at' a date (use `updatedAt`) */
  forecastedAt?: Maybe<Scalars['DateTime']>;
  metrics: Array<AssetMetric>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Asset structure of a Fund scheme */
export enum AssetStructure {
  MultiAsset = 'MULTI_ASSET',
  SingleAsset = 'SINGLE_ASSET'
}

export type AssociatedAccount = {
  __typename: 'AssociatedAccount';
  access: InvestingEntityAccountAccess;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isCommunicationEnabled: Scalars['Boolean'];
  isKeyAccount: Scalars['Boolean'];
  lastName: Scalars['String'];
};

export type AutoReInvestmentTransaction = Transaction & {
  __typename: 'AutoReInvestmentTransaction';
  amount: Money;
  from?: Maybe<MoneyAccount>;
  id: Scalars['ID'];
  pricePerUnit: Money;
  processedAt: Scalars['DateTime'];
  reference?: Maybe<Scalars['String']>;
  to?: Maybe<Fund>;
  /** unit class associated with the transaction */
  unitClass?: Maybe<UnitClass>;
  unitCount: Scalars['Int'];
};

/** Information about units available for reinvestment in a fund */
export type AutoReinvest = {
  __typename: 'AutoReinvest';
  availability: AutoReinvestAvailability;
  /** @deprecated use availability */
  isAvailable: Scalars['Boolean'];
  reinvestUnitPrice?: Maybe<Scalars['Float']>;
  /** @deprecated use reinvestUnitPrice */
  unitPrice?: Maybe<Scalars['Float']>;
};

export enum AutoReinvestAvailability {
  Unavailable = 'UNAVAILABLE',
  UnderwriterTransfer = 'UNDERWRITER_TRANSFER',
  UnitIssue = 'UNIT_ISSUE'
}

export type AutocompleteAddressInput = {
  placeId: Scalars['String'];
  rawSearch: Scalars['String'];
};

/** Represents a bank account */
export type BankAccount = {
  __typename: 'BankAccount';
  accountNumber: Scalars['String'];
  bsbCode?: Maybe<Scalars['String']>;
  currency: Currency;
  id: Scalars['ID'];
  locked: Scalars['Boolean'];
  name: Scalars['String'];
  routingNumber?: Maybe<Scalars['String']>;
  ukSortCode?: Maybe<Scalars['String']>;
};

export enum BankAccountStatus {
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Verified = 'VERIFIED'
}

export type BeginIdentityVerificationInput = {
  id: Scalars['ID'];
  identityVerificationType: IdentityVerificationType;
  /** Token passed if we are verifying an unauthenticated account */
  token?: InputMaybe<Scalars['String']>;
};

export type BeginIdentityVerificationResponse = {
  __typename: 'BeginIdentityVerificationResponse';
  sdkToken: Scalars['String'];
  workflowRunId: Scalars['String'];
};

export type BeneficialOwner = {
  __typename: 'BeneficialOwner';
  address?: Maybe<Address>;
  addressVerificationStatus: BeneficialOwnerVerificationStatus;
  basicDetailsVerificationStatus: BeneficialOwnerVerificationStatus;
  biometricIdentityVerificationStatus: BeneficialOwnerVerificationStatus;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  identityDocumentVerificationStatus: BeneficialOwnerVerificationStatus;
  /** @deprecated use identityDocumentVerificationStatus and biometricIdentityVerificationStatus */
  identityVerificationStatus: BeneficialOwnerVerificationStatus;
  investingEntityName: Scalars['String'];
  investingEntityPrimaryOwnerFirstName: Scalars['String'];
  investingEntityPrimaryOwnerLastName: Scalars['String'];
  investingEntityPrimaryOwnerMiddleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
};

export type BeneficialOwnerContact = {
  __typename: 'BeneficialOwnerContact';
  dateOfBirth?: Maybe<DayMonthYear>;
  email?: Maybe<Scalars['String']>;
  entityName: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<PhoneNumber>;
  relationship: BeneficialOwnerContactRelationship;
};

export type BeneficialOwnerContactDetailsInput = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  mobilePhone?: InputMaybe<PhoneNumberInput>;
};

export enum BeneficialOwnerContactRelationship {
  AppointorOrProtector = 'APPOINTOR_OR_PROTECTOR',
  BearerAndNomineeShareholder = 'BEARER_AND_NOMINEE_SHAREHOLDER',
  BeneficiaryGreaterThan_25Percent = 'BENEFICIARY_GREATER_THAN_25_PERCENT',
  Director = 'DIRECTOR',
  DiscretionaryBeneficiary = 'DISCRETIONARY_BENEFICIARY',
  ExecutorOrAdministrator = 'EXECUTOR_OR_ADMINISTRATOR',
  GeneralPartner = 'GENERAL_PARTNER',
  LimitedPartnerGreaterThan_25Percent = 'LIMITED_PARTNER_GREATER_THAN_25_PERCENT',
  NomineeShareholder = 'NOMINEE_SHAREHOLDER',
  NonDiscretionaryBeneficiary = 'NON_DISCRETIONARY_BENEFICIARY',
  OtherAuthorisedParty = 'OTHER_AUTHORISED_PARTY',
  Partner = 'PARTNER',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  Settlor = 'SETTLOR',
  ShareholdingVotingRightsGreaterThan_25Percent = 'SHAREHOLDING_VOTING_RIGHTS_GREATER_THAN_25_PERCENT',
  Trustee = 'TRUSTEE'
}

export type BeneficialOwnerIdentityDocumentInput = {
  addressCity?: InputMaybe<Scalars['String']>;
  /** ISO 3166-1 alpha-2 country code of the address country */
  addressCountry?: InputMaybe<Scalars['String']>;
  addressLine1?: InputMaybe<Scalars['String']>;
  /** TODO: Make required once implemented */
  addressPlaceId?: InputMaybe<Scalars['String']>;
  addressPostCode?: InputMaybe<Scalars['String']>;
  /** To capture the raw input from the user, in case the search API results don't match */
  addressRawSearch?: InputMaybe<Scalars['String']>;
  addressSuburb?: InputMaybe<Scalars['String']>;
  /** enum one of: '554', '36' */
  country: Scalars['String'];
  /**
   * ## Required Fields:
   * - first-name
   *     - The legal first name(s) of the beneficial owner
   * - last-name
   *     - The legal last name of the beneficial owner
   * - date-of-birth
   *     - ISO 8601 date of birth
   * - If country = NZ and type = passport
   *     - number
   *     - expiry - ISO 8601
   * - If country = NZ and type = driver_licence
   *     - number
   *     - version
   * - If country = AU and type = passport
   *     - number
   *     - gender
   *         - enum one of: 'M', 'F', 'X'
   * - If country = AU and type = driver_licence
   *     - number
   *     - state
   *         - enum one of: 'ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'
   * ## Optional Fields:
   * - middle-name
   * 	- The legal middle name of the beneficial owner
   * - Else `ErrorInvalidArgument`
   */
  fields: Array<IdentityDocumentFieldInput>;
  /** Token passed in query string from email link */
  token: Scalars['String'];
  /** enum one of: 'driver_licence', 'passport' */
  type: Scalars['String'];
};

export enum BeneficialOwnerVerificationStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
  Pending = 'PENDING'
}

export type ButtonLink = {
  __typename: 'ButtonLink';
  pageSlug: Scalars['String'];
  title: Scalars['String'];
};

export type BuyOrder = {
  __typename: 'BuyOrder';
  bidPrice: Money;
  created: Scalars['DateTime'];
  fund: Fund;
  id: Scalars['ID'];
  status: BuyOrderStatus;
  totalValue: Money;
  unitCount: Scalars['Int'];
};

export enum BuyOrderStatus {
  AwaitingTransfer = 'AWAITING_TRANSFER',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Placed = 'PLACED'
}

export type CompanyInvestingEntity = {
  __typename: 'CompanyInvestingEntity';
  /** Registered address of the company */
  address?: Maybe<Address>;
  /** Business number of the company */
  businessNumber?: Maybe<Scalars['String']>;
  /** Company type */
  companyType?: Maybe<InvestingEntityCompanyType>;
  /** Legal name of the company */
  name?: Maybe<Scalars['String']>;
  /** Nature and purpose details for the company */
  natureAndPurposeDetails?: Maybe<NatureAndPurposeDetails>;
  /**
   * NZ PIR
   * @deprecated use taxDetails.nzPrescribedInvestorRate
   */
  nzPrescribedInvestorRate?: Maybe<NzPrescribedInvestorRateBasisPoints>;
  /** Company Registration Number */
  registrationNumber?: Maybe<Scalars['String']>;
  /** Entity tax details */
  taxDetails?: Maybe<EntityTaxDetails>;
  /**
   * Company TIN number
   * @deprecated use taxDetails.primaryTaxIdentificationNumber
   */
  taxIdentificationNumber?: Maybe<Scalars['String']>;
};

/** Represents a company search result. */
export type CompanyResult = {
  __typename: 'CompanyResult';
  countryId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CompleteAccountIdentityVerificationInput = {
  /** Token passed if we are verifying an unauthenticated account */
  token?: InputMaybe<Scalars['String']>;
  workflowRunId: Scalars['ID'];
};

export type CompleteBeneficialOwnerBiometricVerificationInput = {
  token: Scalars['String'];
};

export type CompleteBeneficialOwnerContactDetailsInput = {
  contactDetails: Array<BeneficialOwnerContactDetailsInput>;
};

export type CompleteBeneficialOwnerIdentityVerificationInput = {
  /** Token passed in query string from email link */
  token: Scalars['String'];
  workflowRunId: Scalars['ID'];
};

export type ConfirmAccreditationInput = {
  /** ISO 3166-1 numeric-3 of the investing entity's tax residency country */
  country: Scalars['ID'];
  /** Document uploaded to support the accreditation */
  file: Scalars['Upload'];
  /** The ID of the investing entity that this accreditation is confirmed against */
  id: Scalars['ID'];
};

export type ConfirmAccreditationNotification = InvestingEntityNotification & Notification & {
  __typename: 'ConfirmAccreditationNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type ConfirmAustralianAccreditationInput = {
  adviser?: InputMaybe<Adviser>;
  businessOfFinancialProductsInvestment: Scalars['Boolean'];
  dealerActingAsPrincipal: Scalars['Boolean'];
  /** If `true`, no other values should be `true`, and adviser details should be `null` */
  doesNotQualify: Scalars['Boolean'];
  exemptPublicAuthority: Scalars['Boolean'];
  foreignEntityCoveredByPreceding: Scalars['Boolean'];
  /** The ID of the investing entity that this accreditation is confirmed against */
  id: Scalars['ID'];
  listedEntity: Scalars['Boolean'];
  registeredUnderFinancialCorporationsAct: Scalars['Boolean'];
  regulatedByAPRA: Scalars['Boolean'];
  securitiesInvestmentsExceed10m: Scalars['Boolean'];
  sophisticatedInvestor: Scalars['Boolean'];
  superannuationOrDepositFundAssetsExceed10m: Scalars['Boolean'];
};

export type ConfirmAustralianAccreditationNotification = InvestingEntityNotification & Notification & {
  __typename: 'ConfirmAustralianAccreditationNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type ConfirmBeneficialOwnerIdentityDocumentDetailsInput = {
  /** enum one of: '554', '36' */
  country: Scalars['ID'];
  /** Date of birth information */
  dateOfBirth: DayMonthYearInput;
  /** The legal first name of the account holder */
  firstName: Scalars['String'];
  /**
   * ## Required Fields:
   * - If country = NZ and type = passport
   *     - number
   *     - expiry - ISO 8601 Date
   * - If country = NZ and type = driver_licence
   *     - number
   *     - version
   * - If country = AU and type = passport
   *     - number
   *     - gender
   *         - enum one of: 'M', 'F', 'X'
   * - If country = AU and type = driver_licence
   *     - number
   *     - state
   *         - enum one of: 'ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'
   * - Else `ErrorInvalidArgument`
   */
  identityDocumentFields: Array<IdentityDocumentFieldInput>;
  /** The legal last name of the account holder */
  lastName: Scalars['String'];
  /** The legal middle name of the account holder */
  middleName?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  type: IdentityDocumentType;
};

export type ConfirmEmailAddressNotification = AccountNotification & Notification & {
  __typename: 'ConfirmEmailAddressNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type ConfirmIdentityDocumentDetailsInput = {
  /** enum one of: '554', '36' */
  country: Scalars['ID'];
  /** Date of birth information */
  dateOfBirth: DayMonthYearInput;
  /** The legal first name of the account holder */
  firstName: Scalars['String'];
  /**
   * ## Required Fields:
   * - If country = NZ and type = passport
   *     - number
   *     - expiry - ISO 8601 Date
   * - If country = NZ and type = driver_licence
   *     - number
   *     - version
   * - If country = AU and type = passport
   *     - number
   *     - gender
   *         - enum one of: 'M', 'F', 'X'
   * - If country = AU and type = driver_licence
   *     - number
   *     - state
   *         - enum one of: 'ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'
   * 	- cardNumber
   * 		- if state is one of: 'ACT', 'NSW', 'NT', 'SA', 'TAS', 'WA'
   * - Else `ErrorInvalidArgument`
   */
  identityDocumentFields: Array<IdentityDocumentFieldInput>;
  /** The legal last name of the account holder */
  lastName: Scalars['String'];
  /** The legal middle name of the account holder */
  middleName?: InputMaybe<Scalars['String']>;
  type: IdentityDocumentType;
};

export type ConfirmIdentityDocumentDetailsNotification = AccountNotification & Notification & {
  __typename: 'ConfirmIdentityDocumentDetailsNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type ConfirmNewZealandAccreditationInput = {
  adviser?: InputMaybe<Adviser>;
  authorisedAdviserOrQFE: Scalars['Boolean'];
  /** If `true`, no other values should be `true`, and adviser details should be `null` */
  doesNotQualify: Scalars['Boolean'];
  eligibleInvestor: Scalars['Boolean'];
  /** The ID of the investing entity that this accreditation is confirmed against */
  id: Scalars['ID'];
  investmentDecisionParticipant: Scalars['Boolean'];
  netAssetsExceed5m: Scalars['Boolean'];
  own1mFinancialProducts: Scalars['Boolean'];
  principalBusinessAdvising: Scalars['Boolean'];
  principalBusinessBrokering: Scalars['Boolean'];
  principalBusinessInvesting: Scalars['Boolean'];
  principalBusinessTrading: Scalars['Boolean'];
  schemeManager: Scalars['Boolean'];
  transacted1mFinancialProducts: Scalars['Boolean'];
  turnoverExceeds5m: Scalars['Boolean'];
};

export type ConfirmNewZealandAccreditationNotification = InvestingEntityNotification & Notification & {
  __typename: 'ConfirmNewZealandAccreditationNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type ConfirmUnsupportedCountryAccreditationNotification = InvestingEntityNotification & Notification & {
  __typename: 'ConfirmUnsupportedCountryAccreditationNotification';
  country: Country;
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

/** A content resource such as a blog article */
export type Content = {
  __typename: 'Content';
  cardImageUrl: Scalars['String'];
  id: Scalars['ID'];
  /** @deprecated No longer required by FE */
  overline?: Maybe<Scalars['String']>;
  teaser?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

/** Represents a country object. */
export type Country = {
  __typename: 'Country';
  callingCode: Scalars['String'];
  emoji?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isoAlpha2: Scalars['String'];
  isoAlpha3: Scalars['String'];
  name: Scalars['String'];
};

export type CreateAudBankAccountInput = {
  accountNumber: Scalars['String'];
  bsbCode: Scalars['String'];
  file: Scalars['Upload'];
  investingEntityId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  nickname: Scalars['String'];
};

export type CreateAccountInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  termsAgreed: Scalars['Boolean'];
};

export type CreateAllocationRequestInput = {
  /** `null` if not required - if offer doesn't require accreditation, or if the investor is accredited */
  hasAcknowledgedAccreditationIsRequired?: InputMaybe<Scalars['Boolean']>;
  hasConfirmedReadDocumentation: Scalars['Boolean'];
  investingEntityId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  offerId: Scalars['ID'];
  unitCount: Scalars['Int'];
};

export type CreateAustralianAccreditationInput = {
  adviser?: InputMaybe<Adviser>;
  businessOfFinancialProductsInvestment: Scalars['Boolean'];
  dealerActingAsPrincipal: Scalars['Boolean'];
  exemptPublicAuthority: Scalars['Boolean'];
  foreignEntityCoveredByPreceding: Scalars['Boolean'];
  investingEntityId: Scalars['ID'];
  listedEntity: Scalars['Boolean'];
  registeredUnderFinancialCorporationsAct: Scalars['Boolean'];
  regulatedByAPRA: Scalars['Boolean'];
  securitiesInvestmentsExceed10m: Scalars['Boolean'];
  sophisticatedInvestor: Scalars['Boolean'];
  superannuationOrDepositFundAssetsExceed10m: Scalars['Boolean'];
};

export type CreateCompanyInvestingEntityInput = {
  /** Auto complete registered address of the company */
  autocompleteRegisteredAddress?: InputMaybe<AutocompleteAddressInput>;
  /** Business number of the company */
  businessNumber?: InputMaybe<Scalars['String']>;
  /** Name of the company */
  companyName: Scalars['String'];
  /** Company Registration Number */
  companyRegistrationNumber: Scalars['String'];
  /** Company type */
  companyType: InvestingEntityCompanyType;
  /** RWT rate on interest */
  interestResidentWithholdingTax?: InputMaybe<ResidentWithholdingTaxRateBasisPoints>;
  /** Manual registered address of the company */
  manualRegisteredAddress?: InputMaybe<ManualAddressInput>;
  /** nature and purpose details for the company */
  natureAndPurpose: CreateNatureAndPurposeInput;
  /** NZ PIR */
  nzPrescribedInvestorRate?: InputMaybe<NzPrescribedInvestorRateBasisPoints>;
  /** Primary tax identification number for the investing entity. */
  primaryTaxIdentificationNumber?: InputMaybe<Scalars['String']>;
  /** ISO 3166-1 numeric-3 for the country where the investing entity is a primary tax resident. */
  primaryTaxResidencyCountryId?: InputMaybe<Scalars['ID']>;
  /** Secondary tax identification number for the investing entity. */
  secondaryTaxIdentificationNumber?: InputMaybe<Scalars['String']>;
  /** ISO 3166-1 numeric-3 for the country where the investing entity is a secondary tax resident. */
  secondaryTaxResidencyCountryId?: InputMaybe<Scalars['ID']>;
  /**
   * Company TIN number
   * @deprecated use primaryTaxIdentificationNumber
   */
  taxIdentificationNumber?: InputMaybe<Scalars['String']>;
};

export type CreateEurBankAccountInput = {
  accountNumber: Scalars['String'];
  file: Scalars['Upload'];
  investingEntityId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  nickname: Scalars['String'];
};

export type CreateGbpBankAccountInput = {
  accountNumber: Scalars['String'];
  file: Scalars['Upload'];
  investingEntityId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  nickname: Scalars['String'];
  sortCode: Scalars['String'];
};

export type CreateIndividual = {
  /** Auto complete address of the individual */
  autocompleteAddress?: InputMaybe<AutocompleteAddressInput>;
  /** Email address of the individual required if second individual on joint individual */
  email?: InputMaybe<Scalars['String']>;
  /** First name of the individual */
  firstName: Scalars['String'];
  /** RWT rate on interest */
  interestResidentWithholdingTax?: InputMaybe<ResidentWithholdingTaxRateBasisPoints>;
  /** Last name of the individual */
  lastName: Scalars['String'];
  /** Manual address of the individual */
  manualAddress?: InputMaybe<ManualAddressInput>;
  /** Middle name of the individual */
  middleName?: InputMaybe<Scalars['String']>;
  /** nature and purpose details for the individual */
  natureAndPurpose?: InputMaybe<CreateNatureAndPurposeInput>;
  /** NZ PIR */
  nzPrescribedInvestorRate?: InputMaybe<NzPrescribedInvestorRateBasisPoints>;
  /** Phone number of the individual */
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  /** Place of birth details of the individual */
  placeOfBirthCity?: InputMaybe<Scalars['String']>;
  /** ISO 3166-1 numeric-3 of the individual's place of birth */
  placeOfBirthCountry?: InputMaybe<Scalars['String']>;
  placeOfBirthPlaceId?: InputMaybe<Scalars['String']>;
  placeOfBirthRawSearch?: InputMaybe<Scalars['String']>;
  /** Primary citizenship of the individual */
  primaryCitizenshipId: Scalars['ID'];
  /** Primary tax identification number for the investing entity. */
  primaryTaxIdentificationNumber?: InputMaybe<Scalars['String']>;
  /** ISO 3166-1 numeric-3 for the country where the investing entity is a primary tax resident. */
  primaryTaxResidencyCountryId?: InputMaybe<Scalars['ID']>;
  /** Secondary tax identification number for the investing entity. */
  secondaryTaxIdentificationNumber?: InputMaybe<Scalars['String']>;
  /** ISO 3166-1 numeric-3 for the country where the investing entity is a secondary tax resident. */
  secondaryTaxResidencyCountryId?: InputMaybe<Scalars['ID']>;
  /**
   * tax identification number of the individual
   * @deprecated use primaryTaxIdentificationNumber
   */
  taxIdentificationNumber?: InputMaybe<Scalars['String']>;
  /**
   * ISO 3166-1 numeric-3 of the country where the individual pays tax
   * @deprecated use primaryTaxResidencyCountryId
   */
  taxResidencyCountry?: InputMaybe<Scalars['ID']>;
};

export type CreateIndividualInvestingEntityInput = {
  /** details of the individual */
  individual: CreateIndividual;
};

export type CreateJointIndividualInvestingEntityInput = {
  /** individual one */
  individualOne: CreateIndividual;
  /** individual two */
  individualTwo: CreateIndividual;
};

export type CreateNzdBankAccountInput = {
  accountNumber: Scalars['String'];
  file: Scalars['Upload'];
  investingEntityId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  nickname: Scalars['String'];
};

export type CreateNatureAndPurposeInput = {
  /** Estimate of Funds Available for Investment */
  availableFunds: InvestingEntityAvailableFunds;
  /** The frequency of investment expected from the Investing Entity */
  frequencyOfInvestment: InvestingEntityFrequencyOfInvestment;
  /** The reason for investing */
  reasonForInvesting: InvestingEntityReasonForInvesting;
  /** source of funds */
  sourceOfFunds: SourceOfFundsSource;
};

export type CreateNewZealandAccreditationInput = {
  adviser?: InputMaybe<Adviser>;
  authorisedAdviserOrQFE: Scalars['Boolean'];
  eligibleInvestor: Scalars['Boolean'];
  investingEntityId: Scalars['ID'];
  investmentDecisionParticipant: Scalars['Boolean'];
  netAssetsExceed5m: Scalars['Boolean'];
  own1mFinancialProducts: Scalars['Boolean'];
  principalBusinessAdvising: Scalars['Boolean'];
  principalBusinessBrokering: Scalars['Boolean'];
  principalBusinessInvesting: Scalars['Boolean'];
  principalBusinessTrading: Scalars['Boolean'];
  schemeManager: Scalars['Boolean'];
  transacted1mFinancialProducts: Scalars['Boolean'];
  turnoverExceeds5m: Scalars['Boolean'];
};

export type CreatePartnershipInvestingEntityInput = {
  /** Auto complete registered address of the partnership */
  autocompleteRegisteredAddress?: InputMaybe<AutocompleteAddressInput>;
  /** Business number of the partnership */
  businessNumber?: InputMaybe<Scalars['String']>;
  /** RWT rate on interest */
  interestResidentWithholdingTax?: InputMaybe<ResidentWithholdingTaxRateBasisPoints>;
  /** Manual registered address of the partnership */
  manualRegisteredAddress?: InputMaybe<ManualAddressInput>;
  /** nature and purpose details for the partnership */
  natureAndPurpose: CreateNatureAndPurposeInput;
  /** NZ PIR */
  nzPrescribedInvestorRate?: InputMaybe<NzPrescribedInvestorRateBasisPoints>;
  /** partnership documents */
  partnershipDocuments: Array<Scalars['Upload']>;
  /** name of the partnership */
  partnershipName: Scalars['String'];
  /** partnership type */
  partnershipType: InvestingEntityPartnershipType;
  /** Primary tax identification number for the investing entity. */
  primaryTaxIdentificationNumber?: InputMaybe<Scalars['String']>;
  /** ISO 3166-1 numeric-3 for the country where the investing entity is a primary tax resident. */
  primaryTaxResidencyCountryId?: InputMaybe<Scalars['ID']>;
  /** registration number of the partnership (if applicable) */
  registrationNumber?: InputMaybe<Scalars['String']>;
  /** Secondary tax identification number for the investing entity. */
  secondaryTaxIdentificationNumber?: InputMaybe<Scalars['String']>;
  /** ISO 3166-1 numeric-3 for the country where the investing entity is a secondary tax resident. */
  secondaryTaxResidencyCountryId?: InputMaybe<Scalars['ID']>;
  /**
   * tax identification number of the partnership
   * @deprecated use primaryTaxIdentificationNumber
   */
  taxIdentificationNumber?: InputMaybe<Scalars['String']>;
};

export type CreateSessionFromTokenInput = {
  token: Scalars['String'];
};

export type CreateSessionInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type CreateTrustInvestingEntityInput = {
  /** Auto complete registered address of the company */
  autocompleteRegisteredAddress?: InputMaybe<AutocompleteAddressInput>;
  /** Business number of the trust */
  businessNumber?: InputMaybe<Scalars['String']>;
  /** RWT rate on interest */
  interestResidentWithholdingTax?: InputMaybe<ResidentWithholdingTaxRateBasisPoints>;
  /** Manual registered address of the company */
  manualRegisteredAddress?: InputMaybe<ManualAddressInput>;
  /** nature and purpose details for the trust */
  natureAndPurpose: CreateNatureAndPurposeInput;
  /** NZ PIR */
  nzPrescribedInvestorRate?: InputMaybe<NzPrescribedInvestorRateBasisPoints>;
  /** Primary tax identification number for the investing entity. */
  primaryTaxIdentificationNumber?: InputMaybe<Scalars['String']>;
  /** ISO 3166-1 numeric-3 for the country where the investing entity is a primary tax resident. */
  primaryTaxResidencyCountryId?: InputMaybe<Scalars['ID']>;
  /** registration number of the trust (if applicable) */
  registrationNumber?: InputMaybe<Scalars['String']>;
  /** Secondary tax identification number for the investing entity. */
  secondaryTaxIdentificationNumber?: InputMaybe<Scalars['String']>;
  /** ISO 3166-1 numeric-3 for the country where the investing entity is a secondary tax resident. */
  secondaryTaxResidencyCountryId?: InputMaybe<Scalars['ID']>;
  /**
   * tax identification number of the trust
   * @deprecated use primaryTaxIdentificationNumber
   */
  taxIdentificationNumber?: InputMaybe<Scalars['String']>;
  /** trust documents */
  trustDocuments: Array<Scalars['Upload']>;
  /** name of the trust */
  trustName: Scalars['String'];
  /** trust type */
  trustType: InvestingEntityTrustType;
};

export type CreateUsdBankAccountInput = {
  accountNumber: Scalars['String'];
  file: Scalars['Upload'];
  investingEntityId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  nickname: Scalars['String'];
  routingNumber: Scalars['String'];
};

/** Represents a currency object. */
export type Currency = {
  __typename: 'Currency';
  abbreviation: Scalars['String'];
  decimalSeparator: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  thousandsSeparator: Scalars['String'];
  wallet?: Maybe<Wallet>;
};

/** Date represented by day, month & year */
export type DayMonthYear = {
  __typename: 'DayMonthYear';
  day: Scalars['Int'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

/** Date represented by day, month & year */
export type DayMonthYearInput = {
  day: Scalars['Int'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type DepositTransaction = Transaction & {
  __typename: 'DepositTransaction';
  amount: Money;
  from?: Maybe<MaybeVerifiableBankAccount>;
  id: Scalars['ID'];
  processedAt?: Maybe<Scalars['DateTime']>;
  reference?: Maybe<Scalars['String']>;
  to?: Maybe<MoneyAccount>;
  /** unit class associated with the transaction */
  unitClass?: Maybe<UnitClass>;
};

export type DestroySessionInput = {
  token: Scalars['JWT'];
};

export type DistributionPaymentTransaction = Transaction & {
  __typename: 'DistributionPaymentTransaction';
  amount: Money;
  from?: Maybe<Fund>;
  id: Scalars['ID'];
  processedAt?: Maybe<Scalars['DateTime']>;
  reference?: Maybe<Scalars['String']>;
  to?: Maybe<MoneyAccount>;
  /** unit class associated with the transaction */
  unitClass?: Maybe<UnitClass>;
};

export type DistributionTransaction = Transaction & {
  __typename: 'DistributionTransaction';
  amount: Money;
  exchangeRate?: Maybe<Money>;
  exchangedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Fund>;
  id: Scalars['ID'];
  preExchangeAmount?: Maybe<Money>;
  processedAt?: Maybe<Scalars['DateTime']>;
  reference?: Maybe<Scalars['String']>;
  /** @deprecated to be removed */
  statementUrl?: Maybe<Scalars['URL']>;
  status: DistributionTransactionStatus;
  to?: Maybe<MoneyAccount>;
  /** unit class associated with the transaction */
  unitClass?: Maybe<UnitClass>;
};

export enum DistributionTransactionStatus {
  Complete = 'COMPLETE',
  Pending = 'PENDING',
  Withheld = 'WITHHELD'
}

export type Document = {
  __typename: 'Document';
  category: DocumentCategory;
  file: RemoteAsset;
  fund?: Maybe<Fund>;
  id: Scalars['ID'];
  published: Scalars['DateTime'];
  title: Scalars['String'];
  /** unit class associated with the document */
  unitClass?: Maybe<UnitClass>;
};

export enum DocumentCategory {
  Authority = 'AUTHORITY',
  ChangeOfDetails = 'CHANGE_OF_DETAILS',
  DistributionStatements = 'DISTRIBUTION_STATEMENTS',
  FinancialReports = 'FINANCIAL_REPORTS',
  OfferDocuments = 'OFFER_DOCUMENTS',
  Other = 'OTHER',
  PerformanceReports = 'PERFORMANCE_REPORTS',
  Risk = 'RISK',
  SourceOfFunds = 'SOURCE_OF_FUNDS',
  SourceOfWealth = 'SOURCE_OF_WEALTH',
  SubscriptionDocuments = 'SUBSCRIPTION_DOCUMENTS',
  TaxResidency = 'TAX_RESIDENCY',
  TaxStatements = 'TAX_STATEMENTS',
  UnitCertificates = 'UNIT_CERTIFICATES',
  Withdrawals = 'WITHDRAWALS'
}

export type DocumentSearchFilters = {
  category?: InputMaybe<DocumentCategory>;
  /** @deprecated to be removed */
  endDate?: InputMaybe<Scalars['DateTime']>;
  fundId?: InputMaybe<Scalars['ID']>;
  holdingId?: InputMaybe<Scalars['ID']>;
  /** @deprecated to be removed */
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type DocumentSearchResult = {
  __typename: 'DocumentSearchResult';
  cursor: Scalars['String'];
  node: Document;
};

export type DocumentSearchResults = {
  __typename: 'DocumentSearchResults';
  edges: Array<DocumentSearchResult>;
  pageInfo: PageInfo;
};

export type DocumentSearchSort = {
  direction: SortDirection;
  field: DocumentSearchSortField;
};

export enum DocumentSearchSortField {
  Category = 'CATEGORY',
  Fund = 'FUND',
  Published = 'PUBLISHED',
  Title = 'TITLE',
  UnitClass = 'UNIT_CLASS'
}

export type EurVerifiableBankAccount = VerifiableBankAccount & {
  __typename: 'EURVerifiableBankAccount';
  accountNumber: Scalars['String'];
  created: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  investingEntity: InvestingEntity;
  isActiveAccount: Scalars['Boolean'];
  isDefaultAccount: Scalars['Boolean'];
  name: Scalars['String'];
  nickname: Scalars['String'];
  status: BankAccountStatus;
};

/** Describes the email preferences of a user. */
export type EmailPreferences = {
  __typename: 'EmailPreferences';
  accountActivity: Scalars['Boolean'];
  investmentOpportunities: Scalars['Boolean'];
  /** @deprecated Setting has been removed from UI */
  marketCommentary?: Maybe<Scalars['Boolean']>;
  /** @deprecated Setting has been removed from UI */
  productSurveys?: Maybe<Scalars['Boolean']>;
  /** @deprecated Setting has been removed from UI */
  productUpdates?: Maybe<Scalars['Boolean']>;
};

export type Entity = CompanyInvestingEntity | IndividualInvestingEntity | JointIndividualInvestingEntity | PartnershipInvestingEntity | TrustInvestingEntity;

export type EntityTaxDetails = {
  __typename: 'EntityTaxDetails';
  /** RWT rate on interest */
  interestResidentWithholdingTax?: Maybe<ResidentWithholdingTaxRateBasisPoints>;
  /** NZ PIR */
  nzPrescribedInvestorRate?: Maybe<NzPrescribedInvestorRateBasisPoints>;
  /** Primary tax identification number for the investing entity. */
  primaryTaxIdentificationNumber?: Maybe<Scalars['String']>;
  /** Country where the investing entity is a primary tax resident. */
  primaryTaxResidencyCountry?: Maybe<Country>;
  /** Secondary tax identification number for the investing entity. */
  secondaryTaxIdentificationNumber?: Maybe<Scalars['String']>;
  /** Country where the investing entity is a secondary tax resident. */
  secondaryTaxResidencyCountry?: Maybe<Country>;
  /** @deprecated use primaryTaxIdentificationNumber */
  taxIdentificationNumber?: Maybe<Scalars['String']>;
};

export type FeatureFlag = {
  __typename: 'FeatureFlag';
  isEnabled: Scalars['Boolean'];
  name: Scalars['String'];
};

export type FixedPointNumber = {
  __typename: 'FixedPointNumber';
  /** Number of decimal places */
  decimalPlaces: Scalars['Int'];
  /** Value of the fixed point number */
  value: Scalars['String'];
};

export type FixedPointNumberInput = {
  /** Unit precision scale from the fund */
  decimalPlaces: Scalars['Int'];
  /** Value of the fixed point number */
  value: Scalars['String'];
};

/** Represents a fraction. Used to avoid floating point issues */
export type Fraction = {
  __typename: 'Fraction';
  denominator: Scalars['Int'];
  numerator: Scalars['Int'];
};

export type Fund = {
  __typename: 'Fund';
  asset?: Maybe<Asset>;
  /** Asset structure of the Fund scheme */
  assetStructure: AssetStructure;
  assets: Array<Asset>;
  autoReinvest: AutoReinvest;
  blurb: Scalars['String'];
  cardImageUrl: Scalars['String'];
  currency: Currency;
  /** The fund has been exited if exitDetails is non-null */
  exitDetails?: Maybe<FundExitDetails>;
  heroImageUrl: Scalars['String'];
  id: Scalars['ID'];
  /** Whether the fund is managed and sourced by one of Jasper’s credible partners */
  isMarketplaceFund: Scalars['Boolean'];
  /** @deprecated use metrics */
  keyMetrics?: Maybe<Array<FundMetric>>;
  /** The displayed country where the Fund is based */
  location: Scalars['String'];
  metrics?: Maybe<Array<PortfolioMetric>>;
  /** The displayed title of the Fund */
  name: Scalars['String'];
  openDate?: Maybe<Scalars['DateTime']>;
  /** The offer for the fund, if there is one */
  opportunity?: Maybe<Opportunity>;
  /** The secondary market for the fund, if there is one */
  secondaryMarket?: Maybe<SecondaryMarket>;
  /** When true, an investor with a holding in the fund can view a page with detailed information about their holding */
  showDetailedHoldingPage?: Maybe<Scalars['Boolean']>;
  /** Cash return we predict the Fund will provide, if applicable */
  targetCashReturn?: Maybe<Fraction>;
  /** Total return we predict the Fund will provide, if applicable */
  targetTotalReturn?: Maybe<Fraction>;
  thumbnailImageUrl: Scalars['String'];
  /** unit precision scale for the fund - maximum 9dp */
  unitPrecisionScale?: Maybe<Scalars['Int']>;
  /** The latest price per unit, as determined by the Fund Manager. Nullable if it hasn't been defined yet */
  unitPrice?: Maybe<Money>;
  unitRedemptions?: Maybe<FundUnitRedemptionConfiguration>;
  /** @deprecated use unitPrice */
  unitValue?: Maybe<Money>;
};


export type FundAssetArgs = {
  slug: Scalars['String'];
};

export type FundExitDetails = {
  __typename: 'FundExitDetails';
  overviewMetrics: Array<FundMetric>;
};

export type FundExitTransaction = Transaction & {
  __typename: 'FundExitTransaction';
  amount: Money;
  from?: Maybe<Fund>;
  id: Scalars['ID'];
  processedAt?: Maybe<Scalars['DateTime']>;
  reference?: Maybe<Scalars['String']>;
  to?: Maybe<MoneyAccount>;
  /** unit class associated with the transaction */
  unitClass?: Maybe<UnitClass>;
};

export type FundMetric = {
  __typename: 'FundMetric';
  effectiveDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  index: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type FundStructureOption = {
  __typename: 'FundStructureOption';
  description: Scalars['HTML'];
  id: Scalars['ID'];
  index: Scalars['Int'];
  link?: Maybe<ButtonLink>;
  /** Todo: make required once implemented */
  option?: Maybe<Scalars['String']>;
  optionIsSelected: Scalars['Boolean'];
  title: Scalars['String'];
};

export type FundStructureOptionsBlock = OpportunityContentBlock & {
  __typename: 'FundStructureOptionsBlock';
  fundStructureOptions: Array<FundStructureOption>;
  index: Scalars['Int'];
  title: Scalars['String'];
};

export type FundUnitRedemptionConfiguration = {
  __typename: 'FundUnitRedemptionConfiguration';
  /** Whether redemptions are enabled for the fund */
  enabled: Scalars['Boolean'];
};

export type GbpVerifiableBankAccount = VerifiableBankAccount & {
  __typename: 'GBPVerifiableBankAccount';
  accountNumber: Scalars['String'];
  created: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  investingEntity: InvestingEntity;
  isActiveAccount: Scalars['Boolean'];
  isDefaultAccount: Scalars['Boolean'];
  name: Scalars['String'];
  nickname: Scalars['String'];
  sortCode: Scalars['String'];
  status: BankAccountStatus;
};

export type HelloSignDocument = {
  __typename: 'HelloSignDocument';
  helloSignId: Scalars['ID'];
  id: Scalars['ID'];
  signatures: Array<Maybe<HelloSignSignature>>;
  template?: Maybe<HelloSignDocumentTemplate>;
  viewURL: Scalars['String'];
};

export enum HelloSignDocumentTemplate {
  EligibleInvestorCertificate = 'ELIGIBLE_INVESTOR_CERTIFICATE',
  ProfessionalInvestorCertificate = 'PROFESSIONAL_INVESTOR_CERTIFICATE',
  SafeHarbourCertificate = 'SAFE_HARBOUR_CERTIFICATE',
  SophisticatedInvestorCertificate = 'SOPHISTICATED_INVESTOR_CERTIFICATE'
}

export type HelloSignSignature = {
  __typename: 'HelloSignSignature';
  email: Scalars['String'];
  id: Scalars['ID'];
  isMe: Scalars['Boolean'];
  name: Scalars['String'];
  relationship: HelloSignSignatureRelationship;
  signingURL: Scalars['String'];
  status: HelloSignSignatureStatus;
};

export enum HelloSignSignatureRelationship {
  Adviser = 'ADVISER',
  Investor = 'INVESTOR'
}

export enum HelloSignSignatureStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

export type Holding = {
  __typename: 'Holding';
  bankAccount?: Maybe<AudVerifiableBankAccount | EurVerifiableBankAccount | GbpVerifiableBankAccount | NzdVerifiableBankAccount | UsdVerifiableBankAccount>;
  distributionReinvestmentEnabled?: Maybe<Scalars['Boolean']>;
  distributionSettings?: Maybe<HoldingDistributionSettings>;
  documentCategories?: Maybe<Array<HoldingDocumentCategory>>;
  /** Documents that relate to the fund and this specific investor's holding */
  documents: Array<HoldingDocument>;
  fund: Fund;
  /** @deprecated use totalValue */
  holdingNetAssetValue: Money;
  id: Scalars['ID'];
  keyDates: Array<HoldingKeyDate>;
  /** @deprecated use metrics */
  keyMetrics?: Maybe<Array<HoldingMetric>>;
  liquidationReason?: Maybe<LiquidationReason>;
  /** The metrics for the holding */
  metrics?: Maybe<Array<PortfolioMetric>>;
  returnsTarget: ReturnsTarget;
  slug: Scalars['ID'];
  summary?: Maybe<HoldingSummary>;
  /**
   * The total value (of the holding) based on the Fund unit price and number of units in the holding
   * @deprecated use summary.estimatedValue
   */
  totalValue: Money;
  unitClass: UnitClass;
  /** @deprecated use unitCountDecimal */
  unitCount?: Maybe<Scalars['Int']>;
  unitCountDecimal?: Maybe<FixedPointNumber>;
  /**
   * The latest unit value based on the Fund NAV
   * @deprecated use the value from the fund itself
   */
  unitValue: Money;
  valueChart: PortfolioValueChart;
};


export type HoldingDocumentsArgs = {
  categories?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
};


export type HoldingValueChartArgs = {
  period?: InputMaybe<Scalars['Duration']>;
};

export enum HoldingDistributionSettings {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Withheld = 'WITHHELD'
}

export type HoldingDocument = {
  __typename: 'HoldingDocument';
  category: HoldingDocumentCategory;
  file: RemoteAsset;
  published: Scalars['DateTime'];
  /** Descriptive title of the Document */
  title: Scalars['String'];
};

export type HoldingDocumentCategory = {
  __typename: 'HoldingDocumentCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type HoldingKeyDate = {
  __typename: 'HoldingKeyDate';
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type HoldingMetric = {
  __typename: 'HoldingMetric';
  effectiveDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  tooltip: Scalars['String'];
  value: Scalars['String'];
};

export type HoldingSummary = {
  __typename: 'HoldingSummary';
  /** Total capital committed to the fund. `null` when the hide metrics config of the holding's fund is ON */
  capitalCommitted?: Maybe<Money>;
  /** Total capital paid into the fund since inception. `null` when the hide metrics config of the holding's fund is ON */
  capitalContributed?: Maybe<Money>;
  /** The total amount of capital returned to investors from the holding AKA Distributed Capital */
  capitalReturned?: Maybe<Money>;
  /** The total amount of income returned to investors from the holding AKA Distributed Income */
  cashDistributed?: Maybe<Money>;
  /** The total value (of the holding) based on the Fund unit price and number of units in the holding */
  estimatedValue: Money;
};

/** A key/value pair to provide of identity document information */
export type IdentityDocumentFieldInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum IdentityDocumentType {
  DriverLicence = 'DRIVER_LICENCE',
  Passport = 'PASSPORT'
}

/** Information required to verify a user's identity */
export type IdentityVerificationInput = {
  /** enum one of: '554', '36' */
  country: Scalars['String'];
  /** Date of birth information */
  dayOfBirth: Scalars['Int'];
  /** The legal first name of the account holder */
  firstName: Scalars['String'];
  /**
   * ## Required Fields:
   * - If country = NZ and type = passport
   *     - number
   *     - expiry - ISO 8601 Date
   * - If country = NZ and type = driver_licence
   *     - number
   *     - version
   * - If country = AU and type = passport
   *     - number
   *     - gender
   *         - enum one of: 'M', 'F', 'X'
   * - If country = AU and type = driver_licence
   *     - number
   *     - state
   *         - enum one of: 'ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'
   * - Else `ErrorInvalidArgument`
   */
  identityDocumentFields: Array<IdentityDocumentFieldInput>;
  /** The legal last name of the account holder */
  lastName: Scalars['String'];
  /** The legal middle name of the account holder */
  middleName?: InputMaybe<Scalars['String']>;
  monthOfBirth: Scalars['Int'];
  /**
   * Place of birth information
   * @deprecated Deprecated remove once no longer in use
   */
  placeOfBirthCity?: InputMaybe<Scalars['String']>;
  /** @deprecated Deprecated remove once no longer in use */
  placeOfBirthCountry?: InputMaybe<Scalars['String']>;
  /** @deprecated Deprecated remove once no longer in use */
  placeOfBirthPlaceId?: InputMaybe<Scalars['String']>;
  /** @deprecated Deprecated remove once no longer in use */
  placeOfBirthRawSearch?: InputMaybe<Scalars['String']>;
  /** enum one of: 'passport', 'driver_licence' */
  type: Scalars['String'];
  yearOfBirth: Scalars['Int'];
};

export enum IdentityVerificationType {
  Account = 'ACCOUNT',
  BeneficialOwner = 'BENEFICIAL_OWNER',
  UnauthenticatedAccount = 'UNAUTHENTICATED_ACCOUNT'
}

/** A file that is intended to be displayed in the application as media content. */
export type Image = {
  __typename: 'Image';
  contentType: Scalars['String'];
  index: Scalars['Int'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type IndividualInvestingEntity = {
  __typename: 'IndividualInvestingEntity';
  /** Address */
  address?: Maybe<Address>;
  /** Email address of the individual */
  email?: Maybe<Scalars['String']>;
  /** First name of the individual */
  firstName?: Maybe<Scalars['String']>;
  /** Last name of the individual */
  lastName?: Maybe<Scalars['String']>;
  /** Middle name of the individual */
  middleName?: Maybe<Scalars['String']>;
  /** Nature and purpose details for the individual */
  natureAndPurposeDetails?: Maybe<NatureAndPurposeDetails>;
  /**
   * NZ PIR
   * @deprecated use taxDetails.nzPrescribedInvestorRate
   */
  nzPrescribedInvestorRate?: Maybe<NzPrescribedInvestorRateBasisPoints>;
  /** Phone number of the individual */
  phoneNumber?: Maybe<PhoneNumber>;
  /** Place of birth of the individual */
  placeOfBirth?: Maybe<PlaceOfBirth>;
  /** Primary citizenship of the individual */
  primaryCitizenshipId?: Maybe<Country>;
  /** Entity tax details */
  taxDetails?: Maybe<EntityTaxDetails>;
  /**
   * Tax identification number for the individual
   * @deprecated use taxDetails.primaryTaxIdentificationNumber
   */
  taxIdentificationNumber?: Maybe<Scalars['String']>;
  /**
   * Country where the individual pays tax
   * @deprecated use taxDetails.primaryTaxResidencyCountry
   */
  taxResidencyCountry?: Maybe<Country>;
};

/** Represents the entity under which an account is set up to invest. Either an individual, a company, a trust, or a joint-individual. */
export type InvestingEntity = {
  __typename: 'InvestingEntity';
  accreditation?: Maybe<RetailOnlyAccreditation | WholesaleAccreditation>;
  /** Definition of accreditation is based on the country where the investor is investing from. */
  accreditationCountry?: Maybe<Country>;
  /** @deprecated use entity.address */
  address?: Maybe<Scalars['String']>;
  allocationRequest?: Maybe<AllocationRequest>;
  /** Allocation request(s) in new offers made by (or for) this investing entity */
  allocationRequests: Array<AllocationRequest>;
  /** Accounts with access to this investing entity */
  associatedAccounts: Array<AssociatedAccount>;
  bankAccounts: Array<AudVerifiableBankAccount | EurVerifiableBankAccount | GbpVerifiableBankAccount | NzdVerifiableBankAccount | UsdVerifiableBankAccount>;
  canBeReset: Scalars['Boolean'];
  /** @deprecated use entity.address */
  city?: Maybe<Scalars['String']>;
  /** @deprecated use entity.companyType */
  companyType?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  documents: Array<Maybe<RemoteAsset>>;
  entity: Entity;
  /** @deprecated use entityTypeEnum */
  entityType: Scalars['String'];
  entityTypeEnum: InvestingEntityType;
  /** If this Investing Entity is a joint-individual, the joint-individual second investor details */
  frequencyOfInvestment?: Maybe<InvestingEntityFrequencyOfInvestment>;
  id: Scalars['ID'];
  /** Is this investing entity the selected investing entity for the logged in user Todo: make this field required once implemented */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Is the logged in user the key account holder for this investing entity */
  isKeyAccount?: Maybe<Scalars['Boolean']>;
  /** @deprecated use entity.name */
  name?: Maybe<Scalars['String']>;
  /** Returns a notification for the given type belong to this investing entity, null if there is no such notification */
  notification?: Maybe<AddBankAccountNotification | ConfirmAccreditationNotification | ConfirmAustralianAccreditationNotification | ConfirmNewZealandAccreditationNotification | ConfirmUnsupportedCountryAccreditationNotification | ProvideBeneficialOwnerContactDetailsNotification | ProvideInvestingEntityDocumentsNotification | ProvideSourceOfFundsNotification | ReattemptConfirmAccreditationNotification | ReattemptProvideSourceOfFundsNotification | RenewAccreditationNotification | RenewAustralianAccreditationNotification | RenewNewZealandAccreditationNotification | VerifyAudBankAccountNotification | VerifyEurBankAccountNotification | VerifyGbpBankAccountNotification | VerifyNzdBankAccountNotification | VerifyUsdBankAccountNotification>;
  /** All notifications for this investing entity, optionaly filtered by status */
  notifications: Array<AddBankAccountNotification | ConfirmAccreditationNotification | ConfirmAustralianAccreditationNotification | ConfirmNewZealandAccreditationNotification | ConfirmUnsupportedCountryAccreditationNotification | ProvideBeneficialOwnerContactDetailsNotification | ProvideInvestingEntityDocumentsNotification | ProvideSourceOfFundsNotification | ReattemptConfirmAccreditationNotification | ReattemptProvideSourceOfFundsNotification | RenewAccreditationNotification | RenewAustralianAccreditationNotification | RenewNewZealandAccreditationNotification | VerifyAudBankAccountNotification | VerifyEurBankAccountNotification | VerifyGbpBankAccountNotification | VerifyNzdBankAccountNotification | VerifyUsdBankAccountNotification>;
  /** @deprecated use entity.registrationNumber */
  number?: Maybe<Scalars['String']>;
  /** PIR only relevant to New Zealand-based Investing Entities */
  nzPrescribedInvestorRate?: Maybe<NzPrescribedInvestorRateBasisPoints>;
  /** @deprecated use entity.partnershipType */
  partnershipType?: Maybe<Scalars['String']>;
  /** @deprecated use entity.address */
  postCode?: Maybe<Scalars['String']>;
  reasonForInvesting?: Maybe<InvestingEntityReasonForInvesting>;
  /** @deprecated use relationshipEnum */
  relationship?: Maybe<Scalars['String']>;
  relationshipEnum?: Maybe<InvestingEntityRelationship>;
  /** Search and return a list of documents from investment service for the active investing entity */
  searchDocuments: DocumentSearchResults;
  /** Search and return a list of transactions from transaction service for the active investing entity */
  searchTransactions: TransactionSearchResults;
  /** @deprecated use entity.natureAndPurposeDetails.sourceOfFunds */
  sourceOfFunds?: Maybe<SourceOfFundsSource>;
  /** @deprecated use entity.natureAndPurposeDetails.taxIdentificationNumber */
  taxIdentificationNumber?: Maybe<Scalars['String']>;
  /** @deprecated use entity.trustType */
  trustType?: Maybe<Scalars['String']>;
};


/** Represents the entity under which an account is set up to invest. Either an individual, a company, a trust, or a joint-individual. */
export type InvestingEntityAllocationRequestArgs = {
  id: Scalars['ID'];
};


/** Represents the entity under which an account is set up to invest. Either an individual, a company, a trust, or a joint-individual. */
export type InvestingEntityBankAccountsArgs = {
  isActiveAccount?: Scalars['Boolean'];
};


/** Represents the entity under which an account is set up to invest. Either an individual, a company, a trust, or a joint-individual. */
export type InvestingEntityNotificationArgs = {
  type: NotificationType;
};


/** Represents the entity under which an account is set up to invest. Either an individual, a company, a trust, or a joint-individual. */
export type InvestingEntityNotificationsArgs = {
  status?: InputMaybe<NotificationStatus>;
};


/** Represents the entity under which an account is set up to invest. Either an individual, a company, a trust, or a joint-individual. */
export type InvestingEntitySearchDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<DocumentSearchFilters>;
  first: Scalars['Int'];
  sort?: InputMaybe<DocumentSearchSort>;
};


/** Represents the entity under which an account is set up to invest. Either an individual, a company, a trust, or a joint-individual. */
export type InvestingEntitySearchTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TransactionSearchFilters>;
  first: Scalars['Int'];
  sort?: InputMaybe<TransactionSearchSort>;
};

export enum InvestingEntityAccountAccess {
  Full = 'FULL'
}

export enum InvestingEntityAvailableFunds {
  Between_50KAnd_250K = 'BETWEEN_50K_AND_250K',
  Between_250KAnd_1M = 'BETWEEN_250K_AND_1M',
  Over_1M = 'OVER_1M',
  Under_50K = 'UNDER_50K'
}

export enum InvestingEntityCompanyType {
  LimitedCompany = 'LIMITED_COMPANY',
  NonProfit = 'NON_PROFIT',
  SoleTrader = 'SOLE_TRADER'
}

export enum InvestingEntityFrequencyOfInvestment {
  /** This is synonymous to Ongoing Investment */
  MultipleOffers = 'MULTIPLE_OFFERS',
  OneOff = 'ONE_OFF'
}

/** A notification related to an investing entity */
export type InvestingEntityNotification = {
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export enum InvestingEntityPartnershipType {
  GeneralPartnership = 'GENERAL_PARTNERSHIP',
  LimitedPartnership = 'LIMITED_PARTNERSHIP'
}

export enum InvestingEntityReasonForInvesting {
  CapitalGrowth = 'CAPITAL_GROWTH',
  CapitalPreservation = 'CAPITAL_PRESERVATION',
  EstatePlanning = 'ESTATE_PLANNING',
  OngoingIncome = 'ONGOING_INCOME'
}

export enum InvestingEntityRelationship {
  AppointorOrProtector = 'APPOINTOR_OR_PROTECTOR',
  BearerAndNomineeShareholder = 'BEARER_AND_NOMINEE_SHAREHOLDER',
  BeneficiaryGreaterThan_25Percent = 'BENEFICIARY_GREATER_THAN_25_PERCENT',
  Director = 'DIRECTOR',
  GeneralPartner = 'GENERAL_PARTNER',
  LimitedPartnerGreaterThan_25Percent = 'LIMITED_PARTNER_GREATER_THAN_25_PERCENT',
  NomineeDirector = 'NOMINEE_DIRECTOR',
  NomineeShareholder = 'NOMINEE_SHAREHOLDER',
  NonDiscretionaryBeneficiary = 'NON_DISCRETIONARY_BENEFICIARY',
  OtherAuthorisedParty = 'OTHER_AUTHORISED_PARTY',
  Partner = 'PARTNER',
  ShareholdingVotingRightsGreaterThan_25Percent = 'SHAREHOLDING_VOTING_RIGHTS_GREATER_THAN_25_PERCENT',
  Trustee = 'TRUSTEE'
}

export enum InvestingEntityTrustType {
  CharitableTrust = 'CHARITABLE_TRUST',
  Discretionary = 'DISCRETIONARY',
  Estate = 'ESTATE',
  FamilyTrust = 'FAMILY_TRUST',
  GovernmentSuperannuationFund = 'GOVERNMENT_SUPERANNUATION_FUND',
  NonDiscretionary = 'NON_DISCRETIONARY',
  Other = 'OTHER',
  RegisteredManagedInvestmentScheme = 'REGISTERED_MANAGED_INVESTMENT_SCHEME',
  SelfManagedSuperFund = 'SELF_MANAGED_SUPER_FUND',
  SuperFundTrust = 'SUPER_FUND_TRUST',
  TestamentaryTrust = 'TESTAMENTARY_TRUST',
  TradingTrust = 'TRADING_TRUST',
  UnitTrust = 'UNIT_TRUST'
}

export enum InvestingEntityType {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL',
  JointIndividual = 'JOINT_INDIVIDUAL',
  Partnership = 'PARTNERSHIP',
  Trust = 'TRUST'
}

/** Contains an Account and InvestingEntity object */
export type Investor = {
  __typename: 'Investor';
  account: Account;
  investingEntity: InvestingEntity;
};

export type JointIndividualInvestingEntity = {
  __typename: 'JointIndividualInvestingEntity';
  /** Individual one */
  individualOne: IndividualInvestingEntity;
  /** Individual two */
  individualTwo: IndividualInvestingEntity;
  /** Name of the joint individual enitity */
  name?: Maybe<Scalars['String']>;
};

export enum LiquidationReason {
  /** The holding's fund was exited */
  ExitedFund = 'EXITED_FUND',
  /** The holding was liquidated via the fund's secondary market */
  UnitsSold = 'UNITS_SOLD'
}

export type ManualAddressInput = {
  addressLine1: Scalars['String'];
  /** Administrative area of the address (e.g. state, province, region) */
  administrativeArea?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  /** ISO 3166-1 alpha-2 country code of the address country */
  country: Scalars['String'];
  postCode: Scalars['String'];
  suburb?: InputMaybe<Scalars['String']>;
};

export type MaybeVerifiableBankAccount = AudVerifiableBankAccount | BankAccount | EurVerifiableBankAccount | GbpVerifiableBankAccount | NzdVerifiableBankAccount | UsdVerifiableBankAccount;

/** Represents an amount of monetary value. */
export type Money = {
  __typename: 'Money';
  currency: Currency;
  name: Scalars['String'];
  value: Scalars['Float'];
};

export type MoneyAccount = AudVerifiableBankAccount | BankAccount | EurVerifiableBankAccount | GbpVerifiableBankAccount | NzdVerifiableBankAccount | UsdVerifiableBankAccount | Wallet;

export type Mutation = {
  __typename: 'Mutation';
  activateAccount?: Maybe<NewSession>;
  beginIdentityVerification?: Maybe<BeginIdentityVerificationResponse>;
  /** @deprecated replaced by completeIdentityVerification */
  completeAccountBiometricVerification: Account;
  completeAccountDetails?: Maybe<Account>;
  completeAccountIdentityVerification: Account;
  /** @deprecated replaced by completeIdentityVerification */
  completeBeneficialOwnerBiometricVerification: BeneficialOwner;
  /** Complete contact details for beneficial owners */
  completeBeneficialOwnerContactDetails: InvestingEntity;
  completeBeneficialOwnerIdentityVerification: BeneficialOwner;
  completeUnauthenticatedAccountIdentityVerification: AccountFromToken;
  confirmAccreditation: InvestingEntity;
  /** @deprecated Use `confirmAccreditation` */
  confirmAustralianAccreditation: RetailOnlyAccreditation | WholesaleAccreditation;
  confirmBeneficialOwnerIdentityDocumentDetails: BeneficialOwner;
  confirmIdentityDocumentDetails: Account;
  /** @deprecated Use `confirmAccreditation` */
  confirmNewZealandAccreditation: RetailOnlyAccreditation | WholesaleAccreditation;
  createAUDBankAccount: AudVerifiableBankAccount;
  createAccount?: Maybe<NewSession>;
  createAllocationRequest: AllocationRequest;
  /** @deprecated Use `confirmAustralianAccreditation` */
  createAustralianAccreditation: InvestingEntity;
  /**
   * Create a beneficial owner identity document - always returns true
   * @deprecated Use provideBasicBeneficialOwnerInformation + completeBeneficialOwnerBiometricVerification + confirmBeneficialOwnerIdentityDocumentDetails
   */
  createBeneficialOwnerIdentityDocument: BeneficialOwner;
  createCompanyInvestingEntity: NewSession;
  createEURBankAccount: EurVerifiableBankAccount;
  createGBPBankAccount: GbpVerifiableBankAccount;
  /** @deprecated replaced by completeAccountBiometricVerification + confirmIdentityDocumentDetails */
  createIdentityVerification?: Maybe<Account>;
  createIndividualInvestingEntity: NewSession;
  createJointIndividualInvestingEntity: NewSession;
  createNZDBankAccount: NzdVerifiableBankAccount;
  /** @deprecated Use `confirmNewZealandAccreditation` */
  createNewZealandAccreditation: InvestingEntity;
  createPartnershipInvestingEntity: NewSession;
  createSession?: Maybe<NewSession>;
  createSessionFromToken?: Maybe<NewSession>;
  createTrustInvestingEntity: NewSession;
  createUSDBankAccount: UsdVerifiableBankAccount;
  destroySession?: Maybe<StatusResponse>;
  placeBuyOrder: SellOrder;
  placeSellOrder: Holding;
  provideBasicAccountIdentityDetails: AccountFromToken;
  provideBeneficialOwnerBasicInformation: BeneficialOwner;
  /** @deprecated Deprecated remove once no longer in use */
  provideOutstandingInformation: Account;
  provideSourceOfFunds?: Maybe<InvestingEntity>;
  registerInterest?: Maybe<Offer>;
  requestUnitRedemption: UnitRedemptionRequest;
  resendEmailConfirmation?: Maybe<StatusResponse>;
  /** @deprecated Not required when with many<>many */
  resetInvestingEntity: InvestingEntity;
  resetPassword?: Maybe<StatusResponse>;
  sendChangePasswordEmail?: Maybe<Account>;
  setNewPassword?: Maybe<NewSession>;
  switchInvestingEntity: NewSession;
  updateBankAccount: AudVerifiableBankAccount | EurVerifiableBankAccount | GbpVerifiableBankAccount | NzdVerifiableBankAccount | UsdVerifiableBankAccount;
  updateCompanyInvestingEntity: InvestingEntity;
  updateEmailPreferences?: Maybe<Account>;
  updateHolding: Holding;
  updateIndividualInvestingEntity: InvestingEntity;
  updateInvestingEntityDefaultBankAccount: InvestingEntity;
  updateInvestingEntityHoldingBankAccount: Holding;
  updateJointIndividualInvestingEntity: InvestingEntity;
  updatePartnershipInvestingEntity: InvestingEntity;
  updateProfile?: Maybe<Account>;
  updateTrustInvestingEntity: InvestingEntity;
  uploadAddressVerificationDocument?: Maybe<Account>;
  uploadBankAccountVerificationDocument: AudVerifiableBankAccount | EurVerifiableBankAccount | GbpVerifiableBankAccount | NzdVerifiableBankAccount | UsdVerifiableBankAccount;
  /** Upload a beneficial owner address document */
  uploadBeneficialOwnerAddressDocument: BeneficialOwner;
  uploadInvestingEntityDocuments?: Maybe<Investor>;
  withdrawFromWallet: WalletWithdrawal;
};


export type MutationActivateAccountArgs = {
  form: ActivateAccountInput;
};


export type MutationBeginIdentityVerificationArgs = {
  form: BeginIdentityVerificationInput;
};


export type MutationCompleteAccountDetailsArgs = {
  form: AccountDetailsInput;
};


export type MutationCompleteAccountIdentityVerificationArgs = {
  form: CompleteAccountIdentityVerificationInput;
};


export type MutationCompleteBeneficialOwnerBiometricVerificationArgs = {
  form?: InputMaybe<CompleteBeneficialOwnerBiometricVerificationInput>;
};


export type MutationCompleteBeneficialOwnerContactDetailsArgs = {
  form: CompleteBeneficialOwnerContactDetailsInput;
};


export type MutationCompleteBeneficialOwnerIdentityVerificationArgs = {
  form: CompleteBeneficialOwnerIdentityVerificationInput;
};


export type MutationCompleteUnauthenticatedAccountIdentityVerificationArgs = {
  form: CompleteAccountIdentityVerificationInput;
};


export type MutationConfirmAccreditationArgs = {
  form: ConfirmAccreditationInput;
};


export type MutationConfirmAustralianAccreditationArgs = {
  form: ConfirmAustralianAccreditationInput;
};


export type MutationConfirmBeneficialOwnerIdentityDocumentDetailsArgs = {
  form: ConfirmBeneficialOwnerIdentityDocumentDetailsInput;
};


export type MutationConfirmIdentityDocumentDetailsArgs = {
  form: ConfirmIdentityDocumentDetailsInput;
};


export type MutationConfirmNewZealandAccreditationArgs = {
  form: ConfirmNewZealandAccreditationInput;
};


export type MutationCreateAudBankAccountArgs = {
  form: CreateAudBankAccountInput;
};


export type MutationCreateAccountArgs = {
  account: CreateAccountInput;
};


export type MutationCreateAllocationRequestArgs = {
  form: CreateAllocationRequestInput;
};


export type MutationCreateAustralianAccreditationArgs = {
  form: CreateAustralianAccreditationInput;
};


export type MutationCreateBeneficialOwnerIdentityDocumentArgs = {
  form: BeneficialOwnerIdentityDocumentInput;
};


export type MutationCreateCompanyInvestingEntityArgs = {
  form: CreateCompanyInvestingEntityInput;
};


export type MutationCreateEurBankAccountArgs = {
  form: CreateEurBankAccountInput;
};


export type MutationCreateGbpBankAccountArgs = {
  form: CreateGbpBankAccountInput;
};


export type MutationCreateIdentityVerificationArgs = {
  form: IdentityVerificationInput;
};


export type MutationCreateIndividualInvestingEntityArgs = {
  form: CreateIndividualInvestingEntityInput;
};


export type MutationCreateJointIndividualInvestingEntityArgs = {
  form: CreateJointIndividualInvestingEntityInput;
};


export type MutationCreateNzdBankAccountArgs = {
  form: CreateNzdBankAccountInput;
};


export type MutationCreateNewZealandAccreditationArgs = {
  form: CreateNewZealandAccreditationInput;
};


export type MutationCreatePartnershipInvestingEntityArgs = {
  form: CreatePartnershipInvestingEntityInput;
};


export type MutationCreateSessionArgs = {
  account: CreateSessionInput;
};


export type MutationCreateSessionFromTokenArgs = {
  account: CreateSessionFromTokenInput;
};


export type MutationCreateTrustInvestingEntityArgs = {
  form: CreateTrustInvestingEntityInput;
};


export type MutationCreateUsdBankAccountArgs = {
  form: CreateUsdBankAccountInput;
};


export type MutationDestroySessionArgs = {
  session: DestroySessionInput;
};


export type MutationPlaceBuyOrderArgs = {
  form: PlaceBuyOrderInput;
};


export type MutationPlaceSellOrderArgs = {
  form: PlaceSellOrderInput;
};


export type MutationProvideBasicAccountIdentityDetailsArgs = {
  form: ProvideBasicAccountIdentityDetailsInput;
};


export type MutationProvideBeneficialOwnerBasicInformationArgs = {
  form: ProvideBeneficialOwnerBasicInformationInput;
};


export type MutationProvideOutstandingInformationArgs = {
  form: ProvideOutstandingInformationInput;
};


export type MutationProvideSourceOfFundsArgs = {
  form: ProvideSourceOfFundsInput;
};


export type MutationRegisterInterestArgs = {
  form: RegisterOfferInterestInput;
};


export type MutationRequestUnitRedemptionArgs = {
  form: RequestUnitRedemptionInput;
};


export type MutationResendEmailConfirmationArgs = {
  account: ResendEmailConfirmationInput;
};


export type MutationResetPasswordArgs = {
  account: ResetPasswordInput;
};


export type MutationSetNewPasswordArgs = {
  form: SetNewPasswordInput;
};


export type MutationSwitchInvestingEntityArgs = {
  form: SwitchInvestingEntityInput;
};


export type MutationUpdateBankAccountArgs = {
  form: UpdateBankAccountInput;
};


export type MutationUpdateCompanyInvestingEntityArgs = {
  form: UpdateCompanyInvestingEntityInput;
};


export type MutationUpdateEmailPreferencesArgs = {
  form: UpdateEmailPreferencesInput;
};


export type MutationUpdateHoldingArgs = {
  form: UpdateHoldingInput;
};


export type MutationUpdateIndividualInvestingEntityArgs = {
  form: UpdateIndividualInvestingEntityInput;
};


export type MutationUpdateInvestingEntityDefaultBankAccountArgs = {
  form: UpdateInvestingEntityDefaultBankAccountInput;
};


export type MutationUpdateInvestingEntityHoldingBankAccountArgs = {
  form: UpdateInvestingEntityHoldingBankAccountInput;
};


export type MutationUpdateJointIndividualInvestingEntityArgs = {
  form: UpdateJointIndividualInvestingEntityInput;
};


export type MutationUpdatePartnershipInvestingEntityArgs = {
  form: UpdatePartnershipInvestingEntityInput;
};


export type MutationUpdateProfileArgs = {
  form: UpdateProfileInput;
};


export type MutationUpdateTrustInvestingEntityArgs = {
  form: UpdateTrustInvestingEntityInput;
};


export type MutationUploadAddressVerificationDocumentArgs = {
  form: UploadAddressVerificationDocumentInput;
};


export type MutationUploadBankAccountVerificationDocumentArgs = {
  form: UploadBankAccountVerificationDocumentInput;
};


export type MutationUploadBeneficialOwnerAddressDocumentArgs = {
  form: UploadBeneficialOwnerAddressDocumentInput;
};


export type MutationUploadInvestingEntityDocumentsArgs = {
  form: UploadInvestingEntityDocumentsInput;
};


export type MutationWithdrawFromWalletArgs = {
  form: WithdrawFromWalletInput;
};

export type MutationPermission = {
  __typename: 'MutationPermission';
  mutation: Scalars['String'];
};

export type NzdVerifiableBankAccount = VerifiableBankAccount & {
  __typename: 'NZDVerifiableBankAccount';
  accountNumber: Scalars['String'];
  created: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  investingEntity: InvestingEntity;
  isActiveAccount: Scalars['Boolean'];
  isDefaultAccount: Scalars['Boolean'];
  name: Scalars['String'];
  nickname: Scalars['String'];
  status: BankAccountStatus;
};

/** PIR assigned to New Zealand-based Investing Entities, in basis points */
export enum NzPrescribedInvestorRateBasisPoints {
  Rate_0 = 'RATE_0',
  Rate_1050 = 'RATE_1050',
  Rate_1750 = 'RATE_1750',
  Rate_2800 = 'RATE_2800',
  Unset = 'UNSET'
}

export type NatureAndPurposeDetails = {
  __typename: 'NatureAndPurposeDetails';
  availableFunds?: Maybe<InvestingEntityAvailableFunds>;
  frequencyOfInvestment?: Maybe<InvestingEntityFrequencyOfInvestment>;
  reasonForInvesting?: Maybe<InvestingEntityReasonForInvesting>;
  sourceOfFunds: SourceOfFundsSource;
};

export type NewInvestmentTransaction = Transaction & {
  __typename: 'NewInvestmentTransaction';
  amount: Money;
  from?: Maybe<MoneyAccount>;
  id: Scalars['ID'];
  pricePerUnit: Money;
  processedAt?: Maybe<Scalars['DateTime']>;
  reference?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  to?: Maybe<Fund>;
  /** unit class associated with the transaction */
  unitClass?: Maybe<UnitClass>;
  unitCount: Scalars['Int'];
  unitCountDecimal?: Maybe<FixedPointNumber>;
};

/** Contains the Account and Session objects for the authenticated user. */
export type NewSession = {
  __typename: 'NewSession';
  account: Account;
  features: Array<FeatureFlag>;
  session: Session;
};


/** Contains the Account and Session objects for the authenticated user. */
export type NewSessionFeaturesArgs = {
  flags?: InputMaybe<Array<Scalars['String']>>;
};

export type Notification = {
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export enum NotificationStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

export enum NotificationType {
  AddBankAccount = 'ADD_BANK_ACCOUNT',
  AddInvestingEntity = 'ADD_INVESTING_ENTITY',
  ConfirmAccreditation = 'CONFIRM_ACCREDITATION',
  /** @deprecated CONFIRM_ACCREDITATION will replace this */
  ConfirmAustralianAccreditation = 'CONFIRM_AUSTRALIAN_ACCREDITATION',
  ConfirmEmailAddress = 'CONFIRM_EMAIL_ADDRESS',
  ConfirmIdentityDocumentDetails = 'CONFIRM_IDENTITY_DOCUMENT_DETAILS',
  /** @deprecated CONFIRM_ACCREDITATION will replace this */
  ConfirmNewZealandAccreditation = 'CONFIRM_NEW_ZEALAND_ACCREDITATION',
  ConfirmUnsupportedCountryAccreditation = 'CONFIRM_UNSUPPORTED_COUNTRY_ACCREDITATION',
  ProvideBeneficialOwnerContactDetails = 'PROVIDE_BENEFICIAL_OWNER_CONTACT_DETAILS',
  ProvideCityOfBirth = 'PROVIDE_CITY_OF_BIRTH',
  ProvideInvestingEntityDocuments = 'PROVIDE_INVESTING_ENTITY_DOCUMENTS',
  ProvideSourceOfFunds = 'PROVIDE_SOURCE_OF_FUNDS',
  ReattemptConfirmAccreditation = 'REATTEMPT_CONFIRM_ACCREDITATION',
  ReattemptProvideSourceOfFunds = 'REATTEMPT_PROVIDE_SOURCE_OF_FUNDS',
  ReattemptVerifyAddress = 'REATTEMPT_VERIFY_ADDRESS',
  ReattemptVerifyBiometricIdentity = 'REATTEMPT_VERIFY_BIOMETRIC_IDENTITY',
  RenewAccreditation = 'RENEW_ACCREDITATION',
  /** @deprecated RENEW_ACCREDITATION will replace this */
  RenewAustralianAccreditation = 'RENEW_AUSTRALIAN_ACCREDITATION',
  /** @deprecated RENEW_ACCREDITATION will replace this */
  RenewNewZealandAccreditation = 'RENEW_NEW_ZEALAND_ACCREDITATION',
  VerifyAddress = 'VERIFY_ADDRESS',
  VerifyAudBankAccount = 'VERIFY_AUD_BANK_ACCOUNT',
  VerifyBiometricIdentity = 'VERIFY_BIOMETRIC_IDENTITY',
  VerifyEurBankAccount = 'VERIFY_EUR_BANK_ACCOUNT',
  VerifyGbpBankAccount = 'VERIFY_GBP_BANK_ACCOUNT',
  /** @deprecated VERIFY_BIOMETRIC_IDENTITY + CONFIRM_IDENTITY_DOCUMENT_DETAILS will replace this step */
  VerifyIdentity = 'VERIFY_IDENTITY',
  VerifyNzdBankAccount = 'VERIFY_NZD_BANK_ACCOUNT',
  VerifyUsdBankAccount = 'VERIFY_USD_BANK_ACCOUNT'
}

export type OffMarketTransferTransaction = Transaction & {
  __typename: 'OffMarketTransferTransaction';
  amount: Money;
  fund?: Maybe<Fund>;
  id: Scalars['ID'];
  processedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated no longer required by FE */
  reference?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  /** unit class associated with the transaction */
  unitClass?: Maybe<UnitClass>;
  unitCount: Scalars['Int'];
  unitCountDecimal?: Maybe<FixedPointNumber>;
  unitPrice: Money;
};

/** Describes an investable opportunity */
export type Offer = {
  __typename: 'Offer';
  /** @deprecated no longer needed by FE */
  allocationRequestDisclaimerText?: Maybe<Scalars['HTML']>;
  cardImageUrl?: Maybe<Scalars['String']>;
  /** @deprecated no longer needed by FE */
  contentBlocks: Array<FundStructureOptionsBlock | OfferHighlightsBlock | OfferOverviewBlock | OfferTermsBlock | OpportunityAssetsBlock | OpportunityDocumentsBlock | OpportunityFrequentlyAskedQuestionsBlock | OpportunityGalleryImagesContentBlock | OpportunityHighlightsBlock | OpportunityImagesContentBlock | OpportunityMetricsBlock | OpportunitySingleColumnContentBlock | OpportunityTwoColumnContentBlock>;
  country: Country;
  dataBlocks?: Maybe<Array<OfferTableBlock | OfferTextBlock>>;
  /** @deprecated no longer needed used or updated */
  defaultUnitsPerInvestment?: Maybe<Scalars['Int']>;
  /** Whether the offer is available for digital subscription */
  digitalSubscription: OfferDigitalSubscription;
  documents?: Maybe<Array<Document>>;
  /**
   * If the offer price per unit has not been confirmed, there will be an estimated price.
   * Prefer using this value to `pricePerUnit` & communicate as such to the user!
   */
  estimatedPricePerUnit?: Maybe<Scalars['Float']>;
  fund: Fund;
  fundsDeadline?: Maybe<Scalars['DateTime']>;
  /**
   * Whether the offer IM or PDS is available to authenticated investors or not
   * @deprecated no longer needed by FE
   */
  hasOfferDocumentation: Scalars['Boolean'];
  /** @deprecated no longer needed by FE */
  headerBlocks?: Maybe<Array<FundStructureOptionsBlock | OfferHighlightsBlock | OfferOverviewBlock | OfferTermsBlock | OpportunityAssetsBlock | OpportunityDocumentsBlock | OpportunityFrequentlyAskedQuestionsBlock | OpportunityGalleryImagesContentBlock | OpportunityHighlightsBlock | OpportunityImagesContentBlock | OpportunityMetricsBlock | OpportunitySingleColumnContentBlock | OpportunityTwoColumnContentBlock>>;
  /** Used to filter offer from lists */
  hideOffer?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** Further information relating to the investor querying this offer. `null` if not authenticated */
  investor?: Maybe<OfferInvestor>;
  /** Whether investors in this offer will be required to certify their accreditation */
  isAccreditationRequired: Scalars['Boolean'];
  maxUnitsPerInvestment?: Maybe<Scalars['Int']>;
  minInvestment?: Maybe<Scalars['Float']>;
  minUnitsPerInvestment?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  /**
   * IM or PDS related to the offer. `null` when the documentation has not been
   * prepared yet or the investor is unauthenticated
   * @deprecated no longer needed by FE
   */
  offerDocumentationUrl?: Maybe<Scalars['URL']>;
  openDate?: Maybe<Scalars['DateTime']>;
  overview?: Maybe<Scalars['String']>;
  /** @deprecated no longer needed by FE */
  overviewMetrics: Array<OpportunityMetric>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  primaryDocument?: Maybe<RemoteAsset>;
  slug: Scalars['ID'];
  status: OfferStatus;
  subtitle?: Maybe<Scalars['String']>;
  totalUnitCount?: Maybe<Scalars['Int']>;
  unitsStepCount?: Maybe<Scalars['Int']>;
  videoUrl?: Maybe<Scalars['URL']>;
};

export type OfferDataBlock = {
  id: Scalars['ID'];
  title: Scalars['String'];
};

export enum OfferDigitalSubscription {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type OfferHighlight = {
  __typename: 'OfferHighlight';
  description: Scalars['HTML'];
  index: Scalars['Int'];
  title: Scalars['String'];
};

export type OfferHighlightsBlock = OpportunityContentBlock & {
  __typename: 'OfferHighlightsBlock';
  disclaimer?: Maybe<Scalars['HTML']>;
  highlights: Array<OfferHighlight>;
  index: Scalars['Int'];
  title: Scalars['String'];
};

/** Information relating to the (authenticated) investor who queried the offer */
export type OfferInvestor = {
  __typename: 'OfferInvestor';
  allocationRequests: Array<AllocationRequest>;
  hasRegisteredInterest: Scalars['Boolean'];
  outstandingRequirementsToInvest: Array<OutstandingRequirementToInvest>;
};

export type OfferOverviewBlock = OpportunityContentBlock & {
  __typename: 'OfferOverviewBlock';
  blurb: Scalars['HTML'];
  heroImageUrl: Scalars['String'];
  index: Scalars['Int'];
  shouldTruncateContentBlock: Scalars['Boolean'];
  title: Scalars['String'];
  vimeoVideoId?: Maybe<Scalars['String']>;
};

export enum OfferStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
  Oversubscribed = 'OVERSUBSCRIBED',
  PreOpen = 'PRE_OPEN'
}

export type OfferTableBlock = OfferDataBlock & {
  __typename: 'OfferTableBlock';
  id: Scalars['ID'];
  rows: Array<OfferTableBlockRow>;
  title: Scalars['String'];
};

export type OfferTableBlockRow = {
  __typename: 'OfferTableBlockRow';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type OfferTerm = {
  __typename: 'OfferTerm';
  index: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type OfferTermsBlock = OpportunityContentBlock & {
  __typename: 'OfferTermsBlock';
  disclaimer?: Maybe<Scalars['HTML']>;
  index: Scalars['Int'];
  terms: Array<OfferTerm>;
  title: Scalars['String'];
};

export type OfferTextBlock = OfferDataBlock & {
  __typename: 'OfferTextBlock';
  content: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type Opportunity = Offer;

export type OpportunityAssetsBlock = OpportunityContentBlock & {
  __typename: 'OpportunityAssetsBlock';
  assets: Array<Asset>;
  /** Not all instances will require a description after the assets */
  descriptionAfter?: Maybe<Scalars['HTML']>;
  /** Not all instances will require a description before the assets */
  descriptionBefore?: Maybe<Scalars['HTML']>;
  index: Scalars['Int'];
  title: Scalars['String'];
};

export type OpportunityContentBlock = {
  index: Scalars['Int'];
  title: Scalars['String'];
};

export type OpportunityDocument = {
  __typename: 'OpportunityDocument';
  file: RemoteAsset;
  published: Scalars['DateTime'];
  title: Scalars['String'];
};

export type OpportunityDocumentsBlock = OpportunityContentBlock & {
  __typename: 'OpportunityDocumentsBlock';
  disclaimer?: Maybe<Scalars['HTML']>;
  documents: Array<OpportunityDocument>;
  index: Scalars['Int'];
  title: Scalars['String'];
};

export type OpportunityFrequentlyAskedQuestion = {
  __typename: 'OpportunityFrequentlyAskedQuestion';
  answer: Scalars['HTML'];
  id: Scalars['ID'];
  index: Scalars['Int'];
  question: Scalars['String'];
};

export type OpportunityFrequentlyAskedQuestionsBlock = OpportunityContentBlock & {
  __typename: 'OpportunityFrequentlyAskedQuestionsBlock';
  disclaimer?: Maybe<Scalars['HTML']>;
  frequentlyAskedQuestions: Array<OpportunityFrequentlyAskedQuestion>;
  index: Scalars['Int'];
  title: Scalars['String'];
};

export type OpportunityGalleryImageContent = {
  __typename: 'OpportunityGalleryImageContent';
  imageUrl: Scalars['URL'];
  index: Scalars['Int'];
};

export type OpportunityGalleryImagesContentBlock = OpportunityContentBlock & {
  __typename: 'OpportunityGalleryImagesContentBlock';
  imagesContent: Array<OpportunityGalleryImageContent>;
  index: Scalars['Int'];
  title: Scalars['String'];
};

export type OpportunityHighlight = {
  __typename: 'OpportunityHighlight';
  description: Scalars['HTML'];
  index: Scalars['Int'];
  title: Scalars['String'];
};

export type OpportunityHighlightsBlock = OpportunityContentBlock & {
  __typename: 'OpportunityHighlightsBlock';
  disclaimer?: Maybe<Scalars['HTML']>;
  highlights: Array<OpportunityHighlight>;
  index: Scalars['Int'];
  title: Scalars['String'];
};

export type OpportunityImageContent = {
  __typename: 'OpportunityImageContent';
  /** Not all images will require a description after the image */
  descriptionAfter?: Maybe<Scalars['HTML']>;
  /** Not all images will require a description before the image */
  descriptionBefore?: Maybe<Scalars['HTML']>;
  imageUrl: Scalars['URL'];
  index: Scalars['Int'];
};

export type OpportunityImagesContentBlock = OpportunityContentBlock & {
  __typename: 'OpportunityImagesContentBlock';
  imagesContent: Array<OpportunityImageContent>;
  index: Scalars['Int'];
  title: Scalars['String'];
};

export type OpportunityMetric = {
  __typename: 'OpportunityMetric';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  index: Scalars['Int'];
  name: Scalars['String'];
  tooltip: Scalars['String'];
  value: Scalars['String'];
};

export type OpportunityMetricsBlock = OpportunityContentBlock & {
  __typename: 'OpportunityMetricsBlock';
  /** @deprecated use `disclaimer` */
  description?: Maybe<Scalars['HTML']>;
  disclaimer?: Maybe<Scalars['HTML']>;
  /** Metrics may be forecasted as at a specific date. `null` if metrics are 'as at' a date (use `updatedAt`) */
  forecastedAt?: Maybe<Scalars['DateTime']>;
  index: Scalars['Int'];
  keyMetrics: Array<OpportunityMetric>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OpportunitySingleColumnContentBlock = OpportunityContentBlock & {
  __typename: 'OpportunitySingleColumnContentBlock';
  content: Scalars['HTML'];
  index: Scalars['Int'];
  shouldTruncateContentBlock: Scalars['Boolean'];
  title: Scalars['String'];
};

export type OpportunityTwoColumnContentBlock = OpportunityContentBlock & {
  __typename: 'OpportunityTwoColumnContentBlock';
  content: Scalars['HTML'];
  index: Scalars['Int'];
  title: Scalars['String'];
};

export type OptionalId = {
  value?: InputMaybe<Scalars['ID']>;
};

export type OptionalString = {
  value?: InputMaybe<Scalars['String']>;
};

export enum OutstandingRequirementToInvest {
  AccountSetupIncomplete = 'ACCOUNT_SETUP_INCOMPLETE',
  /** @deprecated replaced by ACKNOWLEDGEMENT_OF_ACCREDITATION_REQUIRED */
  AccreditationRequired = 'ACCREDITATION_REQUIRED',
  AcknowledgementOfAccreditationRequired = 'ACKNOWLEDGEMENT_OF_ACCREDITATION_REQUIRED',
  InvestingEntityIsRetailOnly = 'INVESTING_ENTITY_IS_RETAIL_ONLY',
  InvestingEntityRequired = 'INVESTING_ENTITY_REQUIRED'
}

/** Pagination provider */
export type PageInfo = {
  __typename: 'PageInfo';
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
  totalCount: Scalars['Int'];
};

export type PartnershipInvestingEntity = {
  __typename: 'PartnershipInvestingEntity';
  /** Registered address of the partnership */
  address?: Maybe<Address>;
  /** Business number of the partnership */
  businessNumber?: Maybe<Scalars['String']>;
  /** Legal name of the partnership */
  name?: Maybe<Scalars['String']>;
  /** Nature and purpose details for the partnership */
  natureAndPurposeDetails?: Maybe<NatureAndPurposeDetails>;
  /**
   * NZ PIR
   * @deprecated use taxDetails.nzPrescribedInvestorRate
   */
  nzPrescribedInvestorRate?: Maybe<NzPrescribedInvestorRateBasisPoints>;
  /** partnership type */
  partnershipType?: Maybe<InvestingEntityPartnershipType>;
  /** Partnership Registration Number */
  registrationNumber?: Maybe<Scalars['String']>;
  /** Entity tax details */
  taxDetails?: Maybe<EntityTaxDetails>;
  /**
   * Company TIN number
   * @deprecated use taxDetails.primaryTaxIdentificationNumber
   */
  taxIdentificationNumber?: Maybe<Scalars['String']>;
};

export type Permissions = {
  __typename: 'Permissions';
  mutationPermissions: Array<MutationPermission>;
  typePermissions: Array<TypePermission>;
};

/** Represents a phone number attached to an account. */
export type PhoneNumber = {
  __typename: 'PhoneNumber';
  callingCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  value: Scalars['String'];
};

export type PhoneNumberInput = {
  callingCode: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type PlaceAddress = {
  __typename: 'PlaceAddress';
  /**
   * Administrative area of the address (e.g. state, province, region).
   * Only present on ADDRESS queries
   */
  administrativeArea?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  /** Only present on ADDRESS queries */
  line1?: Maybe<Scalars['String']>;
  /** Only present on ADDRESS queries */
  postCode?: Maybe<Scalars['String']>;
  /** Only present on ADDRESS queries */
  suburb?: Maybe<Scalars['String']>;
};

export type PlaceBuyOrderInput = {
  bidPrice: Scalars['MoneyInput'];
  /** ID of the sell order that the investor is placing a buy order for */
  sellOrderId: Scalars['ID'];
};

/** Represents a place of birth. */
export type PlaceOfBirth = {
  __typename: 'PlaceOfBirth';
  city: Scalars['String'];
  country: Country;
  id: Scalars['ID'];
  placeId?: Maybe<Scalars['String']>;
};

export type PlaceSellOrderInput = {
  askPrice: Scalars['MoneyInput'];
  /** ID of the holding that the investor is requesting a sell order for */
  holdingId: Scalars['ID'];
  unitCount: Scalars['Int'];
};

/** Represents the portfolio of an account. */
export type Portfolio = {
  __typename: 'Portfolio';
  hasInvested?: Maybe<Scalars['Boolean']>;
  holding?: Maybe<Holding>;
  holdingById?: Maybe<Holding>;
  holdings: Array<Holding>;
  liquidatedHoldings: Array<Holding>;
  /** The metrics for the portfolio */
  metrics?: Maybe<Array<PortfolioMetric>>;
  portfolioDistributions: Array<PortfolioDistribution>;
  portfolioOrders: Array<PortfolioOrder>;
  /** When the query was made */
  queriedAt: Scalars['DateTime'];
  totalHoldingsValue: Money;
  /** @deprecated use query for specific order or allocation request */
  valueChart: PortfolioValueChart;
  wallet?: Maybe<Wallet>;
  /** @deprecated No longer used */
  wallets?: Maybe<Array<Wallet>>;
};


/** Represents the portfolio of an account. */
export type PortfolioHoldingArgs = {
  slug: Scalars['ID'];
};


/** Represents the portfolio of an account. */
export type PortfolioHoldingByIdArgs = {
  id: Scalars['ID'];
};


/** Represents the portfolio of an account. */
export type PortfolioPortfolioDistributionsArgs = {
  first: Scalars['Int'];
};


/** Represents the portfolio of an account. */
export type PortfolioValueChartArgs = {
  period?: InputMaybe<Scalars['Duration']>;
};


/** Represents the portfolio of an account. */
export type PortfolioWalletArgs = {
  currencyAbbreviation: Scalars['ID'];
};

export type PortfolioDistribution = {
  __typename: 'PortfolioDistribution';
  amount: Money;
  distributedAt: Scalars['DateTime'];
  fund: Fund;
  statementUrl?: Maybe<Scalars['URL']>;
};

export type PortfolioMetric = {
  __typename: 'PortfolioMetric';
  /** Metric label */
  label: Scalars['String'];
  /** Metric value */
  value: Scalars['String'];
};

export type PortfolioOrder = AllocationRequest | BuyOrder | SellOrder | UnitRedemptionRequest;

export type PortfolioValueChart = {
  __typename: 'PortfolioValueChart';
  /** True if there is data that extends beyond the requested date range */
  hasPointsAcrossRange: Scalars['Boolean'];
  points: Array<PortfolioValueChartPoint>;
};

export type PortfolioValueChartPoint = {
  __typename: 'PortfolioValueChartPoint';
  amount: Money;
  date: Scalars['DateTime'];
};

export type ProvideBasicAccountIdentityDetailsInput = {
  /** If autocompleted, the residential address of the beneficial owner, either this or manualAddress is required */
  autocompleteAddress?: InputMaybe<AutocompleteAddressInput>;
  dateOfBirth: DayMonthYearInput;
  firstName: Scalars['String'];
  /** the id of the account to update */
  id: Scalars['ID'];
  lastName: Scalars['String'];
  /** If manually entered, the residential address of the beneficial owner, either this or autocompleteAddress is required */
  manualAddress?: InputMaybe<ManualAddressInput>;
  middleName?: InputMaybe<Scalars['String']>;
  /** pass the token from the email link to verify updating account details */
  token: Scalars['String'];
};

export type ProvideBeneficialOwnerBasicInformationInput = {
  /** If autocompleted, the residential address of the beneficial owner, either this or manualAddress is required */
  autocompleteAddress?: InputMaybe<AutocompleteAddressInput>;
  consent: Scalars['Boolean'];
  dateOfBirth: DayMonthYearInput;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** If manually entered, the residential address of the beneficial owner, either this or autocompleteAddress is required */
  manualAddress?: InputMaybe<ManualAddressInput>;
  middleName?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type ProvideBeneficialOwnerContactDetailsNotification = InvestingEntityNotification & Notification & {
  __typename: 'ProvideBeneficialOwnerContactDetailsNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type ProvideCityOfBirthNotification = AccountNotification & Notification & {
  __typename: 'ProvideCityOfBirthNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type ProvideInvestingEntityDocumentsNotification = InvestingEntityNotification & Notification & {
  __typename: 'ProvideInvestingEntityDocumentsNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type ProvideOutstandingInformationInput = {
  placeOfBirthCity?: InputMaybe<Scalars['String']>;
  placeOfBirthCountry?: InputMaybe<Scalars['String']>;
  placeOfBirthPlaceId?: InputMaybe<Scalars['String']>;
  placeOfBirthRawSearch?: InputMaybe<Scalars['String']>;
};

export type ProvideSourceOfFundsInput = {
  document: Scalars['Upload'];
  id: Scalars['ID'];
  sourceOfFunds: SourceOfFundsSource;
  /** TODO: Make required once implemented. Explains how the document provided confirms the source of funds, max length 500 */
  supportingInformation?: InputMaybe<Scalars['String']>;
};

export type ProvideSourceOfFundsNotification = InvestingEntityNotification & Notification & {
  __typename: 'ProvideSourceOfFundsNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type Query = {
  __typename: 'Query';
  account?: Maybe<Account>;
  accountFromToken?: Maybe<AccountFromToken>;
  /** Beneficial owner identity - Login not required */
  beneficialOwner: BeneficialOwner;
  /** Get list of beneficial owner contacts for this user's investing entity */
  beneficialOwnerContacts: Array<Maybe<BeneficialOwnerContact>>;
  countries: Array<Maybe<Country>>;
  currencies: Array<Maybe<Currency>>;
  /** @deprecated No longer required by FE */
  featuredContent: Array<Content>;
  features: Array<FeatureFlag>;
  fund?: Maybe<Fund>;
  funds: Array<Fund>;
  geocodePlaceId?: Maybe<PlaceAddress>;
  /**
   * Returns a URL to download a zip of all documents matching the filters
   * @deprecated never supported
   */
  getDocumentArchiveDownloadUrl: Scalars['URL'];
  /** Returns a URL to download a CSV of all transactions matching the filters */
  getTransactionCsvDownloadUrl: Scalars['URL'];
  /**
   * Get list of investing entities for the logged in account
   * @deprecated use account.investingEntities
   */
  investingEntities?: Maybe<Array<InvestingEntity>>;
  /** @deprecated use account.activeInvestingEntity */
  investingEntity?: Maybe<InvestingEntity>;
  /** @deprecated use account */
  me?: Maybe<Account>;
  offer?: Maybe<Offer>;
  offers: Array<Offer>;
  /** @deprecated use offers or sellOrders instead */
  opportunities: Array<Opportunity>;
  /** @deprecated use offer or sellOrder instead */
  opportunity?: Maybe<Opportunity>;
  permissions: Permissions;
  portfolio: Portfolio;
  searchAddress: AddressSearchResults;
  searchCompanies: Array<Maybe<CompanyResult>>;
  /**
   * Search and return a list of documents
   * @deprecated Use `entity.searchDocuments`
   */
  searchDocuments: DocumentSearchResults;
  /**
   * Search and return a list of transactions
   * @deprecated Use `entity.searchTransactions`
   */
  searchTransactions: TransactionSearchResults;
  sellOrder?: Maybe<SellOrder>;
  sellOrders: Array<SellOrder>;
  session?: Maybe<Session>;
};


export type QueryAccountFromTokenArgs = {
  token: Scalars['String'];
};


export type QueryBeneficialOwnerArgs = {
  token: Scalars['String'];
};


export type QueryFeaturesArgs = {
  flags?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryFundArgs = {
  slug: Scalars['ID'];
};


export type QueryGeocodePlaceIdArgs = {
  placeId: Scalars['String'];
  sessionToken?: InputMaybe<Scalars['String']>;
};


export type QueryGetDocumentArchiveDownloadUrlArgs = {
  filters?: InputMaybe<DocumentSearchFilters>;
};


export type QueryGetTransactionCsvDownloadUrlArgs = {
  filters?: InputMaybe<TransactionSearchFilters>;
};


export type QueryOfferArgs = {
  slug: Scalars['ID'];
};


export type QueryOpportunityArgs = {
  slug: Scalars['ID'];
};


export type QuerySearchAddressArgs = {
  query: Scalars['String'];
  searchType: AddressSearchType;
  sessionToken?: InputMaybe<Scalars['String']>;
};


export type QuerySearchCompaniesArgs = {
  countryId: Scalars['ID'];
  query: Scalars['String'];
};


export type QuerySearchDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<DocumentSearchFilters>;
  first: Scalars['Int'];
  sort?: InputMaybe<DocumentSearchSort>;
};


export type QuerySearchTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TransactionSearchFilters>;
  first: Scalars['Int'];
  sort?: InputMaybe<TransactionSearchSort>;
};


export type QuerySellOrderArgs = {
  id: Scalars['ID'];
};

export type ReattemptConfirmAccreditationNotification = InvestingEntityNotification & Notification & {
  __typename: 'ReattemptConfirmAccreditationNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type ReattemptProvideSourceOfFundsNotification = InvestingEntityNotification & Notification & {
  __typename: 'ReattemptProvideSourceOfFundsNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type ReattemptVerifyAddressNotification = AccountNotification & Notification & {
  __typename: 'ReattemptVerifyAddressNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type ReattemptVerifyBiometricIdentityNotification = AccountNotification & Notification & {
  __typename: 'ReattemptVerifyBiometricIdentityNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type RedemptionTransaction = Transaction & {
  __typename: 'RedemptionTransaction';
  amount: Money;
  fund?: Maybe<Fund>;
  id: Scalars['ID'];
  processedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated no longer required by FE */
  reference?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  /** unit class associated with the transaction */
  unitClass?: Maybe<UnitClass>;
  unitCount: Scalars['Int'];
  unitCountDecimal?: Maybe<FixedPointNumber>;
  unitPrice: Money;
};

export type RegisterOfferInterestInput = {
  offerId: Scalars['ID'];
  unitCount: Scalars['Int'];
};

/** A remote file with the header Content-Disposition: Attachment. */
export type RemoteAsset = {
  __typename: 'RemoteAsset';
  contentType: Scalars['String'];
  id: Scalars['ID'];
  index: Scalars['Int'];
  name: Scalars['String'];
  size: Scalars['Int'];
  url: Scalars['String'];
};

export type RenewAccreditationNotification = InvestingEntityNotification & Notification & {
  __typename: 'RenewAccreditationNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type RenewAustralianAccreditationNotification = InvestingEntityNotification & Notification & {
  __typename: 'RenewAustralianAccreditationNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type RenewNewZealandAccreditationNotification = InvestingEntityNotification & Notification & {
  __typename: 'RenewNewZealandAccreditationNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type RequestUnitRedemptionInput = {
  holdingId: Scalars['ID'];
  unitCount?: InputMaybe<Scalars['Int']>;
  unitCountDecimal?: InputMaybe<FixedPointNumberInput>;
};

export type ResendEmailConfirmationInput = {
  email: Scalars['String'];
};

export type ResetPasswordInput = {
  email: Scalars['String'];
};

export enum ResidentWithholdingTaxRateBasisPoints {
  Rate_0 = 'RATE_0',
  Rate_1050 = 'RATE_1050',
  Rate_1200 = 'RATE_1200',
  Rate_1500 = 'RATE_1500',
  Rate_1750 = 'RATE_1750',
  Rate_1900 = 'RATE_1900',
  Rate_2000 = 'RATE_2000',
  Rate_2200 = 'RATE_2200',
  Rate_2800 = 'RATE_2800',
  Rate_3000 = 'RATE_3000',
  Rate_3250 = 'RATE_3250',
  Rate_3300 = 'RATE_3300',
  Rate_3700 = 'RATE_3700',
  Rate_3900 = 'RATE_3900',
  Rate_4500 = 'RATE_4500',
  Rate_4700 = 'RATE_4700'
}

export type RetailOnlyAccreditation = Accreditation & {
  __typename: 'RetailOnlyAccreditation';
  country: Country;
  id: Scalars['ID'];
  updated: Scalars['DateTime'];
};

export enum ReturnsTarget {
  Reinvestment = 'REINVESTMENT',
  Wallet = 'WALLET',
  Withdrawal = 'WITHDRAWAL'
}

export type SecondaryMarket = {
  __typename: 'SecondaryMarket';
  fees: Fraction;
  status: SecondaryMarketStatus;
};

export enum SecondaryMarketStatus {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export type SecondaryUnitPurchaseTransaction = Transaction & {
  __typename: 'SecondaryUnitPurchaseTransaction';
  amount: Money;
  from?: Maybe<Wallet>;
  fund: Fund;
  id: Scalars['ID'];
  processedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated no longer required by FE */
  reference?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  /** unit class associated with the transaction */
  unitClass?: Maybe<UnitClass>;
  unitCount: Scalars['Int'];
  unitPrice: Money;
};

export type SecondaryUnitSaleTransaction = Transaction & {
  __typename: 'SecondaryUnitSaleTransaction';
  amount: Money;
  fees?: Maybe<Money>;
  fund: Fund;
  id: Scalars['ID'];
  processedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated no longer required by FE */
  reference?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  to?: Maybe<Wallet>;
  /** unit class associated with the transaction */
  unitClass?: Maybe<UnitClass>;
  unitCount: Scalars['Int'];
  unitPrice: Money;
};

export type SellOrder = {
  __typename: 'SellOrder';
  askPrice: Money;
  /** A buy order for the active investing entity on this sell order, if they have one */
  buyOrder?: Maybe<BuyOrder>;
  created: Scalars['DateTime'];
  fees: Money;
  fund: Fund;
  id: Scalars['ID'];
  netProceeds: Money;
  status: SellOrderStatus;
  totalValue: Money;
  unitCount: Scalars['Int'];
};

export enum SellOrderStatus {
  AwaitingTransfer = 'AWAITING_TRANSFER',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Open = 'OPEN',
  Requested = 'REQUESTED'
}

/** Represents an active user session authenticated and authorized by a JWT. */
export type Session = {
  __typename: 'Session';
  created: Scalars['DateTime'];
  expires: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Respresents an admin user who is acting on behalf of the account, null if the account holder is the user who logged in. */
  impersonatedBy?: Maybe<Admin>;
  token: Scalars['JWT'];
};

export type SetNewPasswordInput = {
  confirmPassword: Scalars['String'];
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SourceOfFundsSource {
  BusinessIncome = 'BUSINESS_INCOME',
  Gifts = 'GIFTS',
  Inheritances = 'INHERITANCES',
  InvestmentIncome = 'INVESTMENT_INCOME',
  LoanDrawdown = 'LOAN_DRAWDOWN',
  OtherIncome = 'OTHER_INCOME',
  RentalIncome = 'RENTAL_INCOME',
  SalaryAndWages = 'SALARY_AND_WAGES',
  /** This is synonymous to Sale of Asset */
  SaleOfProperty = 'SALE_OF_PROPERTY',
  SaleOfShares = 'SALE_OF_SHARES',
  Superannuation = 'SUPERANNUATION'
}

/** Represents an active user session authenticated and authorized by a JWT. */
export type StatusResponse = {
  __typename: 'StatusResponse';
  message?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
};

export type SwitchInvestingEntityInput = {
  /** ID of the investing entity we are switching to */
  id: Scalars['ID'];
};

export type TaxAttributionTransaction = Transaction & {
  __typename: 'TaxAttributionTransaction';
  amount: Money;
  fund?: Maybe<Fund>;
  id: Scalars['ID'];
  processedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated no longer required by FE */
  reference?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  /** unit class associated with the transaction */
  unitClass?: Maybe<UnitClass>;
  unitCount: Scalars['Int'];
  unitPrice: Money;
};

export type Transaction = {
  amount: Money;
  id: Scalars['ID'];
  processedAt?: Maybe<Scalars['DateTime']>;
  reference?: Maybe<Scalars['String']>;
  /** unit class associated with the transaction */
  unitClass?: Maybe<UnitClass>;
};

export type TransactionEdge = {
  __typename: 'TransactionEdge';
  cursor: Scalars['String'];
  node: AutoReInvestmentTransaction | DepositTransaction | DistributionPaymentTransaction | DistributionTransaction | FundExitTransaction | NewInvestmentTransaction | OffMarketTransferTransaction | RedemptionTransaction | SecondaryUnitPurchaseTransaction | SecondaryUnitSaleTransaction | TaxAttributionTransaction | WithdrawalTransaction;
};

export type TransactionSearchFilters = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  fundId?: InputMaybe<Scalars['ID']>;
  holdingId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  transactionType?: InputMaybe<TransactionType>;
};

export type TransactionSearchResults = {
  __typename: 'TransactionSearchResults';
  edges: Array<TransactionEdge>;
  pageInfo: PageInfo;
};

export type TransactionSearchSort = {
  direction: SortDirection;
  field: TransactionSearchSortField;
};

export enum TransactionSearchSortField {
  Amount = 'AMOUNT',
  Fund = 'FUND',
  ProcessedAt = 'PROCESSED_AT',
  TransactionType = 'TRANSACTION_TYPE'
}

export enum TransactionStatus {
  Complete = 'COMPLETE',
  Pending = 'PENDING'
}

export enum TransactionType {
  AutoReinvestment = 'AUTO_REINVESTMENT',
  Deposit = 'DEPOSIT',
  Distribution = 'DISTRIBUTION',
  DistributionPayment = 'DISTRIBUTION_PAYMENT',
  FundExit = 'FUND_EXIT',
  NewInvestment = 'NEW_INVESTMENT',
  OffMarket = 'OFF_MARKET',
  Redemption = 'REDEMPTION',
  SecondaryUnitPurchase = 'SECONDARY_UNIT_PURCHASE',
  SecondaryUnitSale = 'SECONDARY_UNIT_SALE',
  TaxAttribution = 'TAX_ATTRIBUTION',
  Withdrawal = 'WITHDRAWAL'
}

export type TrustInvestingEntity = {
  __typename: 'TrustInvestingEntity';
  /** Registered address of the trust */
  address?: Maybe<Address>;
  /** Business number of the trust */
  businessNumber?: Maybe<Scalars['String']>;
  /** Legal name of the trust */
  name?: Maybe<Scalars['String']>;
  /** Nature and purpose details for the trust */
  natureAndPurposeDetails?: Maybe<NatureAndPurposeDetails>;
  /**
   * NZ PIR
   * @deprecated use taxDetails.nzPrescribedInvestorRate
   */
  nzPrescribedInvestorRate?: Maybe<NzPrescribedInvestorRateBasisPoints>;
  /** Trust Registration Number */
  registrationNumber?: Maybe<Scalars['String']>;
  /** Entity tax details */
  taxDetails?: Maybe<EntityTaxDetails>;
  /**
   * Company TIN number
   * @deprecated use taxDetails.primaryTaxIdentificationNumber
   */
  taxIdentificationNumber?: Maybe<Scalars['String']>;
  /** trust type */
  trustType?: Maybe<InvestingEntityTrustType>;
};

export type TypePermission = {
  __typename: 'TypePermission';
  type: Scalars['String'];
};

export type UsdVerifiableBankAccount = VerifiableBankAccount & {
  __typename: 'USDVerifiableBankAccount';
  accountNumber: Scalars['String'];
  created: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  investingEntity: InvestingEntity;
  isActiveAccount: Scalars['Boolean'];
  isDefaultAccount: Scalars['Boolean'];
  name: Scalars['String'];
  nickname: Scalars['String'];
  routingNumber: Scalars['String'];
  status: BankAccountStatus;
};

export type UnitClass = {
  __typename: 'UnitClass';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UnitRedemptionRequest = {
  __typename: 'UnitRedemptionRequest';
  created: Scalars['DateTime'];
  holding: Holding;
  id: Scalars['ID'];
  status: UnitRedemptionStatus;
  /** @deprecated use unitCountDecimal */
  unitCount: Scalars['Int'];
  unitCountDecimal?: Maybe<FixedPointNumber>;
  unitPrice: Money;
};

export enum UnitRedemptionStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Requested = 'REQUESTED'
}

export type UpdateAddressInput = {
  /** Auto complete address of the individual */
  autocompleteAddress?: InputMaybe<AutocompleteAddressInput>;
  /** Manual address of the individual */
  manualAddress?: InputMaybe<ManualAddressInput>;
};

export type UpdateBankAccountInput = {
  id: Scalars['ID'];
  nickname?: InputMaybe<Scalars['String']>;
};

export type UpdateCompanyInvestingEntityInput = {
  /** update address details for the company */
  address?: InputMaybe<UpdateAddressInput>;
  /** Business number of the company */
  businessNumber?: InputMaybe<OptionalString>;
  /** Company Registration Number */
  companyRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** Company type */
  companyType?: InputMaybe<InvestingEntityCompanyType>;
  /** ID Of Investing Entity */
  id: Scalars['ID'];
  /** nature and purpose details for the company */
  natureAndPurpose?: InputMaybe<UpdateNatureAndPurposeInput>;
  /** Tax details */
  taxDetails?: InputMaybe<UpdateTaxDetails>;
};

export type UpdateEmailPreferencesInput = {
  /** @deprecated Setting is no longer editable */
  accountActivity?: InputMaybe<Scalars['Boolean']>;
  investmentOpportunities: Scalars['Boolean'];
  /** @deprecated Setting has been removed from UI */
  marketCommentary?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Setting has been removed from UI */
  productSurveys?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Setting has been removed from UI */
  productUpdates?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateHoldingInput = {
  id: Scalars['ID'];
  returnsTarget?: InputMaybe<ReturnsTarget>;
};

export type UpdateIndividualInput = {
  /** Address of the individual */
  address?: InputMaybe<UpdateAddressInput>;
  /** nature and purpose details for the individual */
  natureAndPurpose?: InputMaybe<UpdateNatureAndPurposeInput>;
  /** Phone number of the individual */
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  /** Place of birth details of the individual */
  placeOfBirthCity?: InputMaybe<Scalars['String']>;
  /** ISO 3166-1 alpha-2 country code of the place of birth */
  placeOfBirthCountryISOAlpha2?: InputMaybe<Scalars['String']>;
  /** Google place ID of the place of birth */
  placeOfBirthPlaceId?: InputMaybe<Scalars['String']>;
  /** Raw search string for the place of birth */
  placeOfBirthRawSearch?: InputMaybe<Scalars['String']>;
  /** Primary citizenship of the individual */
  primaryCitizenshipId?: InputMaybe<Scalars['ID']>;
  /** update the tax details for the individual */
  taxDetails?: InputMaybe<UpdateTaxDetails>;
};

export type UpdateIndividualInvestingEntityInput = {
  /** investing entity ID */
  id: Scalars['ID'];
  /** individual */
  individual?: InputMaybe<UpdateIndividualInput>;
};

export type UpdateInvestingEntityDefaultBankAccountInput = {
  /** ID of the bank account */
  bankAccountId: Scalars['ID'];
  /** ID of the Investing entity to update default bank account for */
  investingEntityId: Scalars['ID'];
  isDefaultBankAccount: Scalars['Boolean'];
};

export type UpdateInvestingEntityHoldingBankAccountInput = {
  /** ID of the bank account to be used for the holding's distributions */
  bankAccountId: Scalars['ID'];
  /** ID of the holding to update bank account for */
  holdingId: Scalars['ID'];
  /** ID of the investing entity */
  id: Scalars['ID'];
};

export type UpdateJointIndividualInvestingEntityInput = {
  /** investing entity ID */
  id: Scalars['ID'];
  /** individual one */
  individualOne?: InputMaybe<UpdateIndividualInput>;
  /** individual two */
  individualTwo?: InputMaybe<UpdateIndividualInput>;
};

export type UpdateNatureAndPurposeInput = {
  /** Estimate of Funds Available for Investment */
  availableFunds?: InputMaybe<InvestingEntityAvailableFunds>;
  /** The frequency of investment expected from the Investing Entity */
  frequencyOfInvestment?: InputMaybe<InvestingEntityFrequencyOfInvestment>;
  /** The reason for investing */
  reasonForInvesting?: InputMaybe<InvestingEntityReasonForInvesting>;
  /** source of funds */
  sourceOfFunds?: InputMaybe<SourceOfFundsSource>;
};

export type UpdatePartnershipInvestingEntityInput = {
  /** update address details for the partnership */
  address?: InputMaybe<UpdateAddressInput>;
  /** Business number of the partnership */
  businessNumber?: InputMaybe<OptionalString>;
  /** id of the investing entity */
  id: Scalars['ID'];
  /** nature and purpose details for the partnership */
  natureAndPurpose?: InputMaybe<UpdateNatureAndPurposeInput>;
  /** partnership type */
  partnershipType?: InputMaybe<InvestingEntityPartnershipType>;
  /** registration number of the partnership */
  registrationNumber?: InputMaybe<Scalars['String']>;
  /** tax details for the partnership */
  taxDetails?: InputMaybe<UpdateTaxDetails>;
};

export type UpdateProfileInput = {
  autocompleteAddress?: InputMaybe<AutocompleteAddressInput>;
  callingCode?: InputMaybe<Scalars['String']>;
  manualAddress?: InputMaybe<ManualAddressInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type UpdateTaxDetails = {
  /** RWT rate on interest */
  interestResidentWithholdingTax?: InputMaybe<ResidentWithholdingTaxRateBasisPoints>;
  /** NZ PIR */
  nzPrescribedInvestorRate?: InputMaybe<NzPrescribedInvestorRateBasisPoints>;
  /** Primary tax identification number for the investing entity. */
  primaryTaxIdentificationNumber?: InputMaybe<OptionalString>;
  /** ISO 3166-1 numeric-3 for the country where the investing entity is a primary tax resident. */
  primaryTaxResidencyCountryId?: InputMaybe<OptionalId>;
  /** Secondary tax identification number for the investing entity. */
  secondaryTaxIdentificationNumber?: InputMaybe<OptionalString>;
  /** ISO 3166-1 numeric-3 for the country where the investing entity is a secondary tax resident. */
  secondaryTaxResidencyCountryId?: InputMaybe<OptionalId>;
  /**
   * Tax Identification Number
   * @deprecated use primaryTaxIdentificationNumber
   */
  taxIdentificationNumber?: InputMaybe<Scalars['String']>;
  /**
   * ISO 3166-1 numeric-3 of the tax residency country
   * @deprecated use primaryTaxResidencyCountryId
   */
  taxResidencyCountryId?: InputMaybe<Scalars['ID']>;
};

export type UpdateTrustInvestingEntityInput = {
  /** update address details for the trust */
  address?: InputMaybe<UpdateAddressInput>;
  /** Business number of the trust */
  businessNumber?: InputMaybe<OptionalString>;
  /** id of the investing entity */
  id: Scalars['ID'];
  /** nature and purpose details for the individual */
  natureAndPurpose?: InputMaybe<UpdateNatureAndPurposeInput>;
  /** registration number of the trust (if applicable) */
  registrationNumber?: InputMaybe<Scalars['String']>;
  /** tax details for the trust */
  taxDetails?: InputMaybe<UpdateTaxDetails>;
  /** trust type */
  trustType?: InputMaybe<InvestingEntityTrustType>;
};

export type UploadAddressVerificationDocumentInput = {
  addressCity?: InputMaybe<Scalars['String']>;
  /** ISO 3166-1 alpha-2 country code of the address country */
  addressCountry?: InputMaybe<Scalars['String']>;
  /** The following are required if any are present */
  addressLine1?: InputMaybe<Scalars['String']>;
  addressPlaceId?: InputMaybe<Scalars['String']>;
  addressPostCode?: InputMaybe<Scalars['String']>;
  /** To capture the raw input from the user, in case the search API results don't match */
  addressRawSearch?: InputMaybe<Scalars['String']>;
  addressSuburb?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
};

export type UploadBankAccountVerificationDocumentInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  bsbCode?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  routingNumber?: InputMaybe<Scalars['String']>;
  sortCode?: InputMaybe<Scalars['String']>;
};

export type UploadBeneficialOwnerAddressDocumentInput = {
  addressCity?: InputMaybe<Scalars['String']>;
  /** ISO 3166-1 alpha-2 country code of the address country */
  addressCountry?: InputMaybe<Scalars['String']>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressPlaceId?: InputMaybe<Scalars['String']>;
  addressPostCode?: InputMaybe<Scalars['String']>;
  /** The following fields are deprecated - use autocompleteAddress or manualAddress */
  addressRawSearch?: InputMaybe<Scalars['String']>;
  addressSuburb?: InputMaybe<Scalars['String']>;
  /** The beneficial owner's residential address. If updating address & if updated via 'autocompletion', otherwise `null`. May not be provided in addition to manualAddress. */
  autocompleteAddress?: InputMaybe<AutocompleteAddressInput>;
  file: Scalars['Upload'];
  /** The beneficial owner's residential address. If updating address & if updated via 'manual entry', otherwise `null`. May not be provided in addition to autocompleteAddress. */
  manualAddress?: InputMaybe<ManualAddressInput>;
  token: Scalars['String'];
};

export type UploadInvestingEntityDocumentsInput = {
  files: Array<Scalars['Upload']>;
  investingEntityId: Scalars['ID'];
};

export type VerifiableBankAccount = {
  created: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  investingEntity: InvestingEntity;
  isActiveAccount: Scalars['Boolean'];
  isDefaultAccount: Scalars['Boolean'];
  name: Scalars['String'];
  nickname: Scalars['String'];
  status: BankAccountStatus;
};

export type VerifyAudBankAccountNotification = InvestingEntityNotification & Notification & {
  __typename: 'VerifyAUDBankAccountNotification';
  bankAccount: AudVerifiableBankAccount;
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type VerifyAddressNotification = AccountNotification & Notification & {
  __typename: 'VerifyAddressNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type VerifyBiometricIdentityNotification = AccountNotification & Notification & {
  __typename: 'VerifyBiometricIdentityNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type VerifyEurBankAccountNotification = InvestingEntityNotification & Notification & {
  __typename: 'VerifyEURBankAccountNotification';
  bankAccount: EurVerifiableBankAccount;
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type VerifyGbpBankAccountNotification = InvestingEntityNotification & Notification & {
  __typename: 'VerifyGBPBankAccountNotification';
  bankAccount: GbpVerifiableBankAccount;
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type VerifyIdentityNotification = AccountNotification & Notification & {
  __typename: 'VerifyIdentityNotification';
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type VerifyNzdBankAccountNotification = InvestingEntityNotification & Notification & {
  __typename: 'VerifyNZDBankAccountNotification';
  bankAccount: NzdVerifiableBankAccount;
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type VerifyUsdBankAccountNotification = InvestingEntityNotification & Notification & {
  __typename: 'VerifyUSDBankAccountNotification';
  bankAccount: UsdVerifiableBankAccount;
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  status: NotificationStatus;
};

export type Wallet = {
  __typename: 'Wallet';
  balance: Money;
  currency: Currency;
  depositDetails: WalletDepositDetails;
  id: Scalars['ID'];
  proportionOfTotalPortfolio: Fraction;
  searchTransactions: TransactionSearchResults;
};


export type WalletSearchTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type WalletDepositDetails = {
  __typename: 'WalletDepositDetails';
  bankAccount: BankAccount;
  reference: Scalars['String'];
};

export type WalletWithdrawal = {
  __typename: 'WalletWithdrawal';
  transaction: WithdrawalTransaction;
  wallet: Wallet;
};

/** TODO: Break this implementation out into the specified types of 'wholesale' accreditation */
export type WholesaleAccreditation = Accreditation & {
  __typename: 'WholesaleAccreditation';
  /** @deprecated will be using certificateURL moving forward */
  certificate?: Maybe<RemoteAsset>;
  certificateURL?: Maybe<Scalars['URL']>;
  country: Country;
  /** @deprecated will be using certificateURL moving forward */
  document?: Maybe<HelloSignDocument>;
  id: Scalars['ID'];
  signedAt?: Maybe<Scalars['DateTime']>;
  status: AccreditationStatus;
  updated: Scalars['DateTime'];
};

export type WithdrawFromWalletInput = {
  amount: Scalars['Float'];
  currencyId: Scalars['ID'];
};

export type WithdrawalTransaction = Transaction & {
  __typename: 'WithdrawalTransaction';
  amount: Money;
  from?: Maybe<MoneyAccount>;
  id: Scalars['ID'];
  processedAt?: Maybe<Scalars['DateTime']>;
  reference?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  to?: Maybe<AudVerifiableBankAccount | EurVerifiableBankAccount | GbpVerifiableBankAccount | NzdVerifiableBankAccount | UsdVerifiableBankAccount>;
  /** unit class associated with the transaction */
  unitClass?: Maybe<UnitClass>;
};
